import React from "react"

import classNames from "classnames/bind"
import styles from "../../styles/Main.module.scss"

import Modal from "../common/modal/ModalWrapper"
import DeviceMargin from "../common/DeviceMargin"
import Logo from "./Upper/Logo"
import SearchBar from "./Upper/SearchBar"
import SeparatorLine from "./SeparatorLine"

import Ranking from "./Ranking/Ranking"
import Choice from "./Choice/Choice"
import Review from "./Review/Review"

class Main extends React.Component {
  state = {}

  render() {
    return (
      <React.Fragment>
        <div className={cn("upper")}>
          <DeviceMargin height="66" />
          <Logo />
          <SearchBar />
        </div>
        <div className={cn("contents")}>
          <Ranking />
          <SeparatorLine />
          <Choice />
          <SeparatorLine />
          <Review />
        </div>
      </React.Fragment>
    )
  }
}

const cn = classNames.bind(styles)

export default Main
