import React, { Component } from "react"

import classNames from "classnames/bind"
import styles from "../../styles/Detail_n.module.scss"
import { connect } from "react-redux"

export class Tabs extends Component {
  render() {
    const { numberOfComments } = this.props

    return (
      <>
        <div className={cn("sep_line")}></div>

        <div className={cn("section_tabs")}>
          <div className={cn("section_tab_wrapper")}>
            <div className={cn("section_tab", "section_tab_selected")}>스낵 플레이</div>
            <div className={cn("section_tab_bar", "section_tab_bar_selected")}></div>
          </div>
          <div className={cn("section_tab_wrapper")}>
            <div className={cn("section_tab")}>스낵 토크({numberOfComments})</div>
            <div className={cn("section_tab_bar")}></div>
          </div>
          <div className={cn("section_tab_wrapper")}>
            <div className={cn("section_tab")}>상세 정보</div>
            <div className={cn("section_tab_bar")}></div>
          </div>
        </div>
        <div className={cn("sep_line_thick")}></div>
      </>
    )
  }
}

const cn = classNames.bind(styles)

const mapStateToProps = state => ({
  numberOfComments: state.detail.comments.length,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Tabs)
