import React from "react"

import { calculateNumberOfStars } from "../../util/index"

import star_full from "../../assets/star_full.svg"
import star_half from "../../assets/star_half.svg"
import star_empty from "../../assets/star_empty.svg"

const RatingStarImages = ({ rating }) => {
  const numberOfStars = calculateNumberOfStars(rating)
  const starImages = []

  for (let i = 0; i < numberOfStars.numberOfFullStars; i++) starImages.push(<img src={star_full} alt="star" key={"0" + i} />)
  for (let i = 0; i < numberOfStars.numberOfHalfStars; i++) starImages.push(<img src={star_half} alt="star" key={"1" + i} />)
  for (let i = 0; i < numberOfStars.numberOfEmptyStars; i++) starImages.push(<img src={star_empty} alt="star" key={"2" + i} />)

  return <>{starImages}</>
}

export default RatingStarImages
