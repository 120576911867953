const INITIAL_STATE = {
  productID: 1,
  productInfo: {},
  productTags: [],
  productDiscountTags: [],
  productEvaluationInfo: {
    rating: 0.0,
    ratedCount: 0,
    likeCount: 0,
    ifUserLikeProduct: 0,
    ifUserRateProduct: 0,
  },
  tags: [],
  discountTags: [],
  comments: [],
  isLoading: true,
}

export default INITIAL_STATE
