import React from "react"

import classNames from "classnames/bind"
import styles from "../../../styles/Main.module.scss"

import ChoiceContent from "./ChoiceContent"

const cn = classNames.bind(styles)

class ChoiceSlider extends React.Component {
  render() {
    return (
      <div className={cn("choice_slider")}>
        <ChoiceContent imgUrl="https://s3.ap-northeast-2.amazonaws.com/img.snackpot.kr/c1.jpg" webUrl="http://blog.snackpot.kr/221740681691" />
        <ChoiceContent imgUrl="https://s3.ap-northeast-2.amazonaws.com/img.snackpot.kr/c2.jpg" />
        <ChoiceContent imgUrl="https://s3.ap-northeast-2.amazonaws.com/img.snackpot.kr/c3.jpg" />
      </div>
    )
  }
}

export default ChoiceSlider
