export const MAX_NUMBER_OF_PRODUCTS = 200;
export const MINIMUM_NUMBER_OF_PRODUCTS = 10;

export const TUTORIAL_STATUS = {
  NEW_USER: 0,
  USER_FINISHING_TUTORIAL: 1,
  USER_SKIPPING_TUTORIAL: 2,
};

/* 0 : 신규 유저, 1: 튜토리얼 완료한 유저, 2: 기존 유저지만 튜토리얼 건너뛰기 한 유저 */
