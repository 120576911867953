export const groupByDiscount = [
  { name: "1+1", icon: "/images/search/brandicon/oneplusone.png", url: "" },
  { name: "2+1", icon: "/images/search/brandicon/twoplusone.png", url: "" },
  { name: "3+1", icon: "/images/search/cookie.svg", url: "" },
]

export const groupByBrand = [
  { name: "CU", icon: "/images/search/brandicon/cu.png", url: "" },
  { name: "GS25", icon: "/images/search/brandicon/gs25.png", url: "" },
  { name: "이마트24", icon: "/images/search/brandicon/emart24.png", url: "" },
  { name: "세븐일레븐", icon: "/images/search/brandicon/seveneleven.png", url: "" },
  { name: "미니스톱", icon: "/images/search/brandicon/ministop.png", url: "" },
  { name: "스타벅스", icon: "/images/search/brandicon/starbucks.png", url: "" },
  { name: "던킨도너츠", icon: "/images/search/brandicon/dunkin.png", url: "" },
  { name: "베스킨라빈스", icon: "/images/search/brandicon/br31.png", url: "" },
  { name: "할리스커피", icon: "/images/search/brandicon/hollys.png", url: "" },
  { name: "이디야커피", icon: "/images/search/brandicon/ediya.png", url: "" },
]

export const groupByCategory = [
  { name: "아이스크림", icon: "/images/search/icecream.svg", url: "" },
  { name: "머핀", icon: "/images/search/muffin.svg", url: "" },
  { name: "쿠키", icon: "/images/search/cookie.svg", url: "" },
  { name: "캔디", icon: "/images/search/candy.svg", url: "" },
  { name: "음료", icon: "/images/search/beverage.svg", url: "" },
]
