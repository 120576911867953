import React from "react"

import classNames from "classnames/bind"
import styles from "../../styles/SearchBar.module.scss"
import { withRouter } from "react-router"
import { connect } from "react-redux"

class SearchBar extends React.Component {
  inputRef = React.createRef()

  pressEnterKey = e => {
    if (e.which === 13) this.executeSearch()
  }

  executeSearch = () => {
    if (this.inputRef.current.value.length < 2) {
      alert("두 글자 이상 입력해주세요.")
    } else {
      this.props.history.push(`/search/${this.inputRef.current.value}`)
    }
  }

  componentDidMount() {
    this.setInitialValue()

    if (this.props.location.pathname === "/search_by_keyword") {
      this.inputRef.current.focus()
    }
  }

  componentDidUpdate() {
    this.setInitialValue()

    if (this.props.location.pathname === "/search_by_keyword") {
      this.inputRef.current.focus()
    }
  }

  setInitialValue = () => {
    this.inputRef.current.value = this.props.keyword || ""
  }

  clearValue = () => {
    this.inputRef.current.value = ""
    this.inputRef.current.focus()
  }

  goBack = () => {
    this.props.history.goBack()
  }

  render() {
    return (
      <>
        <div className={cn("top_search")}>
          <div className={cn("back_btn")} onClick={this.goBack}>
            <img src="/images/back_btn.svg" alt="" />
          </div>
          <div className={cn("search_bar")}>
            <input
              type="text"
              className={cn("text_field")}
              spellCheck="false"
              ref={this.inputRef}
              onKeyDown={this.pressEnterKey}
            />
            <div className={cn("clear_btn")} onClick={this.clearValue}>
              <img src="/images/search_bar_clear_btn.svg" alt="" />
            </div>
          </div>
          <div className={cn("barcode_btn")}>
            <img src="/images/barcode_search.svg" alt="" />
          </div>
        </div>
        <div className={cn("placeholder")}></div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  keyword: state.search_result.keyword,
})

const cn = classNames.bind(styles)

export default connect(mapStateToProps)(withRouter(SearchBar))
