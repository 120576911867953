import { TUTORIAL_STATUS } from "../../constants/tutorial";

const INITIAL_STATE = {
  authenticated: false,

  // the unique identifier for an user
  userID: -1,

  user: {
    id: 0 /* the unique identifier for an user */,
    displayName: "" /* nickname an user uses */,
    age: "" /* a range of ages of an user */,
    provider: "" /* naver, kakao, facebook */,
    tutorial: TUTORIAL_STATUS.NEW_USER,
  },
};

export default INITIAL_STATE;
