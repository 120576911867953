import Slider from "@material-ui/core/Slider"
import { withStyles } from "@material-ui/core/styles"

const sliderStyle = {
  root: {
    width: "100%",
    height: "100%",
    padding: "0",
    opacity: "0",
  },
  thumb: {
    width: "28px",
    height: "28px",
    color: "red",
    marginTop: "0px",
    marginLeft: "0",
    opacity: "0",
  },
  track: {
    width: "100%",
    height: "100%",
    opacity: "0",
  },
  rail: {
    width: "100%",
    height: "100%",
    opacity: "0",
  },
}

const RatingSlider = withStyles(sliderStyle)(Slider)

export default RatingSlider
