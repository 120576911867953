import React from "react"

import classNames from "classnames/bind"
import styles from "../../../styles/Detail_comments.module.scss"

import api from "../../../api/index"
import MESSAGE from "../../../constants/message"
import { connect } from "react-redux"

import Comment from "./Comment"
import CommentWriteButton from "./CommentWriteButton"
import SeparatorLine from "./SeparatorLine"

class UserComments extends React.Component {
  state = {
    needReload: false,
    hasMyComment: false,
    showCommentWriteSection: false,
  }

  reloadComments = () => {
    this.setState({ needReload: true })
  }

  render() {
    const { comments } = this.props

    return (
      <section className={cn("user_comment")}>
        <div className={cn("user_comment_title")}>
          <span className={cn("title")}>{MESSAGE.USER_COMMENTS.SECTION_TITLE}</span>
          <span className={cn("more")}>{MESSAGE.USER_COMMENTS.MORE}</span>
        </div>

        <CommentWriteButton />

        <SeparatorLine />

        {comments.map((item, index) => (
          <React.Fragment key={index}>
            <Comment commentInfo={item} reload={this.getCommentsFromApi} />
            <SeparatorLine />
          </React.Fragment>
        ))}

        <div style={{ height: "200px" }}></div>
      </section>
    )
  }
}

const cn = classNames.bind(styles)

const mapStateToProps = state => ({
  authenticated: state.auth.authenticated,
  productID: state.detail.productID,
  comments: state.detail.comments,
})

export default connect(mapStateToProps)(UserComments)
