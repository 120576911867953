import React from "react"

import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import classNames from "classnames/bind"
import styles from "../../styles/tutorial/intro.module.scss"

export class Intro extends React.Component {

  inputCheck = () => {
    const { history } = this.props

    history.push("/tutorial/rating")
  }

  render() {
    return (
      <>
        {/* <Snackbar open={this.state.open} autoHideDuration={3000} onClose={this.setClose}>
          <MuiAlert severity="warning" elevation={6} variant="filled">
            조금 더 남았어요!
          </MuiAlert>
        </Snackbar> */}
        {/* <Alert>hi</Alert> */}
        <div className={cn("upper")}>
          <div className={cn("text")}>
            너의
            <br />
            <span className={cn("bold-text")}>과자취향이</span>
            <br />
            궁금해?
          </div>
          <img src="/images/tutorial/yellow.svg" className={cn("yellow-img")} alt="" />
          <img src="/images/tutorial/red.svg" className={cn("red-img")} alt="" />
          <img src="/images/tutorial/orange.svg" className={cn("orange-img")} alt="" />
        </div>
        <div className={cn("bottom")}>
          <div className={cn("title")}>정보를 입력해 주세요.</div>
          <div>
            <div>5000여개의 데이터를 토대로 <br/> 취향을 분석해드려요.</div>
            <div>즐겨먹는 과자를 평가하고 내 과자 취향을 알아보고 캐릭터를 볼 수 있습니다.</div>
            <p></p>
            <div>건너뛰기</div>
          </div>
        </div>
        <div className={cn("next-btn")} onClick={this.inputCheck}>
          <div className={cn("next-btn-text")}>시작하기</div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({})

const cn = classNames.bind(styles)

export default connect(mapStateToProps)(withRouter(Intro))
