import React from "react"

import classNames from "classnames/bind"
import styles from "../../../../styles/NewComment.module.scss"

import RankingSlider from "./RatingSlider"
import RatingStarImages from "../../../common/RatingStarImages"

class RatingStars extends React.Component {
  state = {
    rating: 5.0,
  }

  handleSliderChange = (e, newValue) => {
    this.setState({ rating: newValue })
    this.props.setRating(newValue)
  }

  render() {
    const { rating } = this.state

    return (
      <div className={cn("stars")}>
        <div className={cn("hidden_slider")}>
          <RankingSlider min={0} max={5.0} step={0.5} onChange={this.handleSliderChange} />
        </div>
        <div>
          <RatingStarImages rating={rating} />
        </div>
      </div>
    )
  }
}

const cn = classNames.bind(styles)

export default RatingStars
