import { apiURL } from "../constants/api"
import baseAPI from "./base"
import mockAPI from "./mock"

const actualAPI = {
  getProductInfo: id => baseAPI.get(apiURL.GET_PRODUCT_INFO(id)),
  getProductInfoV2: id => baseAPI.get(apiURL.GET_PRODUCT_INFO_V2(id)),
  getTagInfo: id => baseAPI.get(apiURL.GET_TAG_INFO(id)),
  getProductEvaluationInfo: productID => baseAPI.get(apiURL.GET_PRODUCT_EVALUATION_INFO(productID)),
  /* */
  getProductRating: productID => baseAPI.put(apiURL.GET_PRODUCT_RATING(productID)),
  setProductRating: (productID, rating) => baseAPI.put(apiURL.SET_PRODUCT_RATING(productID), rating),
  getProductLike: productID => baseAPI.get(apiURL.GET_PRODUCT_LIKE(productID)),
  increaseProductLike: productID => baseAPI.put(apiURL.INCREASE_PRODUCT_LIKE(productID)),
  cancelProductLike: productID => baseAPI.delete(apiURL.CANCEL_PRODUCT_LIKE(productID)),
  /* */
  searchItem: (keyword, searchConfig, searchConditionQueryStr) =>
    baseAPI.get(apiURL.SEARCH_ITEM(keyword), { params: { ...searchConfig, q: searchConditionQueryStr } }),
  newComment: (productID, commentData) => baseAPI.put(apiURL.NEW_COMMENT(productID), commentData),
  getComment: productID => baseAPI.get(apiURL.GET_COMMENT(productID)),
  increaseCommentLike: commentID => baseAPI.put(apiURL.INCREASE_COMMENT_LIKE(commentID)),
  cancelCommentLike: commentID => baseAPI.delete(apiURL.CANCEL_COMMENT_LIKE(commentID)),
  /* */
  getAuthInfo: () => baseAPI.get(apiURL.GET_AUTH_INFO()),
  finishTutorial: () => baseAPI.post(apiURL.FINISH_TUTORIAL()),
  skipTutorial: () => baseAPI.post(apiURL.SKIP_TUTORIAL()),
  sendTutorialResult: (name, gender, ageSpan, elapsedTime, cluster, selectedCount, canceledCount, productSelection) =>
    baseAPI.post(apiURL.SEND_TUTORIAL_RESULT(), {
      name,
      gender,
      ageSpan,
      elapsedTime,
      cluster,
      selectedCount,
      canceledCount,
      productSelection,
    }),
}

const api = process.env.REACT_APP_USE_MOCK_API ? mockAPI : actualAPI

export default api
