import React from "react"

import classNames from "classnames/bind"
import styles from "../../styles/tutorial/rating.module.scss"
import Product from "./Product"

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { setEntranceTime, setSelectedCount, setFinished } from "../../store/tutorial"
import { countVaildRatings, shuffleArray } from "../../util"
import productList from "../../constants/tutorial/productList"
import { MAX_NUMBER_OF_PRODUCTS, MINIMUM_NUMBER_OF_PRODUCTS } from "../../constants/tutorial"

import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"

export class Rating extends React.Component {
  state = {
    entranceTime: undefined,
    openAlert: true,
  }

  setClose = () => {
    this.setState({ openAlert: false })
  }

  shuffledProductList = shuffleArray(productList)

  goToResult = () => {
    const { history, dispatch } = this.props

    if (countVaildRatings(this.props.productSelection) >= MINIMUM_NUMBER_OF_PRODUCTS) {
      dispatch(setSelectedCount(countVaildRatings(this.props.productSelection)))

      history.push("/tutorial/result")
    } else alert("최소 " + MINIMUM_NUMBER_OF_PRODUCTS + "개는 선택해야해요.")
  }

  componentDidMount() {
    const { dispatch, finished, history } = this.props

    if (finished) history.push("/result")

    dispatch(setEntranceTime())
  }

  render() {
    let numOfSelection = countVaildRatings(this.props.productSelection)
    console.log(numOfSelection)
    return (
      <>
        <div className={cn("upper-bg")}>
          <div className={cn("title-text")}>
            {numOfSelection < MINIMUM_NUMBER_OF_PRODUCTS / 2 && (
              <>
                최소 <span>10개</span>의<br />
                과자를
                <br />
                평가해주세요!
              </>
            )}
            {numOfSelection >= MINIMUM_NUMBER_OF_PRODUCTS / 2 && numOfSelection < MINIMUM_NUMBER_OF_PRODUCTS - 1 && (
              <>
                조금밖에
                <br />안 남았어요!
              </>
            )}
            {numOfSelection >= MINIMUM_NUMBER_OF_PRODUCTS - 1 && (
              <>
                평가를 더 할수록
                <br />
                <span>정확한 취향</span>
                을<br /> 분석해드려요!
              </>
            )}
          </div>
          <div
            className={cn("circle")}
            style={
              numOfSelection <= 10
                ? { background: circleBgColorCss[numOfSelection] }
                : { background: circleBgColorCss[10] }
            }
          >
            <div className={cn("inner-circle")}>
              <span className={cn("selected-count")}>{numOfSelection}</span>
            </div>
            <div
              className={cn("next-btn", numOfSelection >= MINIMUM_NUMBER_OF_PRODUCTS && "blink_me")}
              onClick={this.goToResult}
            >
              <img src="/images/tutorial/arrow-front-1.svg" alt="" />
            </div>
          </div>
        </div>
        <div className={cn("lower-bg")}>
          <div className={cn("list-wrapper")}>
            {this.shuffledProductList.map((item, index) => (
              <Product productID={item.productID.toString()} productInfo={item} key={item.productID} />
            ))}
          </div>
        </div>

        {numOfSelection >= 10 && (
          <Snackbar open={this.state.openAlert} autoHideDuration={3000} onClose={this.setClose}>
            <MuiAlert severity="warning" elevation={6} variant="filled">
              더 많이 평가를 할수록 정확한 취향을 분석해드려요!
            </MuiAlert>
          </Snackbar>
        )}
      </>
    )
  }
}

const circleBgColorCss = {
  0: "black",
  1: "linear-gradient(90deg, black 50%, transparent 50%), linear-gradient(-54deg, black 50%, #fbb687 50%)",
  2: "linear-gradient(90deg, black 50%, transparent 50%), linear-gradient(-18deg, black 50%, #fbb687 50%)",
  3: "linear-gradient(90deg, black 50%, transparent 50%), linear-gradient(18deg, black 50%, #fbb687 50%)",
  4: "linear-gradient(90deg, black 50%, transparent 50%), linear-gradient(54deg, black 50%, #fbb687 50%)",
  5: "linear-gradient(90deg, black 50%, transparent 50%), linear-gradient(90deg, black 50%, #fbb687 50%)",
  6: "linear-gradient(270deg, #fbb687 50%, transparent 50%), linear-gradient(-54deg, #fbb687 50%, black 50%)",
  7: "linear-gradient(270deg, #fbb687 50%, transparent 50%), linear-gradient(-18deg, #fbb687 50%, black 50%)",
  8: "linear-gradient(270deg, #fbb687 50%, transparent 50%), linear-gradient(18deg, #fbb687 50%, black 50%)",
  9: "linear-gradient(270deg, #fbb687 50%, transparent 50%), linear-gradient(54deg, #fbb687 50%, black 50%)",
  10: "linear-gradient(270deg, #fbb687 50%, transparent 50%), linear-gradient(90deg, #fbb687 50%, black 50%)",
}

const cn = classNames.bind(styles)

const mapStateToProps = state => ({
  finished: state.tutorial.finished,
  productSelection: state.tutorial.productSelection,
})

export default connect(mapStateToProps)(withRouter(Rating))
