import React, { useEffect, useRef } from "react"
import { useSelector } from "react-redux"

const ResultCount = () => {
  const { resultCount } = useSelector(state => ({
    resultCount: state.search_result.resultCount,
  }))

  return <div>총 {resultCount}개의 결과가 있습니다.</div>
}

export default ResultCount
