import React from "react"
import ReactDOM from "react-dom"
import MainRouter from "./router"
import * as serviceWorker from "./serviceWorker"

import { preventDoubleTap, preventPinchZoom } from "../src/util/touchEvent"

ReactDOM.render(<MainRouter />, document.getElementById("root"))

preventDoubleTap()
preventPinchZoom()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
