import React from "react"

import classNames from "classnames/bind"
import styles from "../../../styles/Main.module.scss"

const cn = classNames.bind(styles)

class ReviewContent extends React.Component {
  render() {
    return <div className={cn("review_content")}></div>
  }
}

export default ReviewContent
