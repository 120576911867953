import React from "react"

import styles from "../../styles/Main.module.scss"

const SeparatorLine = () => {
  return (
    <div className={styles.separator}>
      <div className={styles.sep_top}></div>
      <div className={styles.sep_btm}></div>
    </div>
  )
}

export default SeparatorLine
