import React, { Component } from "react"

import classNames from "classnames/bind"
import styles from "../../styles/Navigation_n.module.scss"

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { setCurrentPage } from "../../store/common/index"

export class NavigationButton extends Component {
  handleNavBtn = page => {
    this.props.dispatch(setCurrentPage(page))
    this.props.history.push(page)
  }

  render() {
    const { url, currentPage } = this.props

    return (
      <div
        className={cn("navbar_item")}
        onClick={() => {
          this.handleNavBtn(url)
        }}
      >
        {url === currentPage ? (
          <>
            <img className={cn(url.substring(1))} alt="" src={"/images/nav_" + url.substring(1) + "_selected.svg"} />
            <div className={cn("item_txt", "txt_selected")}>{this.props.value}</div>
          </>
        ) : (
          <>
            <img className={cn(url.substring(1))} alt="" src={"/images/nav_" + url.substring(1) + ".svg"} />
            <div className={cn("item_txt")}>{this.props.value}</div>
          </>
        )}
      </div>
    )
  }
}

const cn = classNames.bind(styles)

const mapStateToProps = (state, ownProps) => {
  return {
    showNavigation: state.common.navigation.showNavigation,
    currentPage: state.common.navigation.currentPage,
  }
}

export default connect(mapStateToProps)(withRouter(NavigationButton))
