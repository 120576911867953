export function preventDoubleTap() {
  document.getElementById("root").addEventListener("click", event => {})
}

export function preventPinchZoom() {
  document.addEventListener(
    "touchstart",
    event => {
      if (event.touches.length > 1) {
        event.preventDefault()
        event.stopPropagation()
      }
    },
    { passive: false }
  )
}
