import createReducer from "../common";
import api from "../../api/index";
import AUTH_INITIAL_STATE from "./state";
import { TUTORIAL_STATUS } from "../../constants/tutorial";

const id = "auth";

/* Action Constants */
const SET_IS_AUTHENTICATED = `${id}/SET_IS_AUTHENTICATED`;
const SET_AUTH_INFO = `${id}/SET_AUTH_INFO`;
const SET_FINISH_TUTORIAL = `${id}/SET_AUTH_INFO/FINISH_TUTORIAL`;
const SET_SKIP_TUTORIAL = `${id}/SET_AUTH_INFO/SKIP_TUTORIAL`;

/* Actions Creators */
export const setIsAuthenticated = (isAuthenticated) => ({
  type: SET_IS_AUTHENTICATED,
  isAuthenticated,
});
export const setAuthInfo = (authInfo) => ({ type: SET_AUTH_INFO, authInfo });
export const setFinishTutorial = () => ({ type: SET_FINISH_TUTORIAL });
export const setSkipTutorial = () => ({ type: SET_SKIP_TUTORIAL });

/* Thunk Middlewares */
export const getAuthInfo = () => (dispatch) =>
  api.getAuthInfo().then((res) => {
    dispatch(setIsAuthenticated(res.data.authenticated));

    if (res.data.authenticated) {
      dispatch(setAuthInfo(res.data));
      return res.data;
    }
  });

/* Thunk Middlewares */
export const finishTutorialRequest = () => (dispatch, getState) =>
  api.finishTutorial().then(() => {
    dispatch(setFinishTutorial());
  });

/* Reducers */
const reducer = createReducer(AUTH_INITIAL_STATE, {
  [SET_IS_AUTHENTICATED]: (state, action) =>
    (state.authenticated = action.isAuthenticated),
  [SET_AUTH_INFO]: (state, action) => {
    state.userID = action.authInfo.user.userID;
    state.user = action.authInfo.user;
  },
  [SET_FINISH_TUTORIAL]: (state) =>
    (state.user.tutorial = TUTORIAL_STATUS.USER_FINISHING_TUTORIAL),
  [SET_SKIP_TUTORIAL]: (state) =>
    (state.user.tutorial = TUTORIAL_STATUS.USER_SKIPPING_TUTORIAL),
});

export default reducer;
