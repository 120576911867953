import React from "react"

import MODAL from "../../../constants/modal"

import LoginModal from "./ModalComponents/LoginModal"

const ModalMap = {
  [MODAL.LOGIN_MODAL]: <LoginModal />,
}

export default ModalMap
