import React from "react"

import classNames from "classnames/bind"
import styles from "../../styles/Navigation_n.module.scss"

import { connect } from "react-redux"
import { withRouter, matchPath } from "react-router-dom"

import { PAGES } from "../../constants/index"
import NavigationButton from "./NavigationButton"

class Navigation extends React.Component {
  pagesToShowNav = [PAGES.HOME, PAGES.SEARCH, PAGES.RANKING, PAGES.SOCIAL]

  render() {
    const { pathname } = this.props.location
    const ifPageNeedsNav = matchPath(pathname, { path: this.pagesToShowNav, exact: true })

    if (ifPageNeedsNav) {
      return (
        <>
          {/* <FooterMargin /> */}
          <div className={cn("app_nav")}>
            <div className={cn("app_nav_wrapper")}>
              <div className={cn("navbar")}>
                <NavigationButton url={PAGES.HOME} value="홈" />
                <NavigationButton url={PAGES.SEARCH} value="검색" />
                <div className={cn("navbar_item_center_space")}></div>
                <NavigationButton url={PAGES.RANKING} value="랭킹" />
                <NavigationButton url={PAGES.SOCIAL} value="소셜" />
              </div>
              <div
                className={cn("pot")}
                onClick={() => {
                  window.location.reload()
                }}
              >
                <img src="/images/pot_icon.svg" className={cn("pot_icon")} alt="" />
              </div>
              <div className={cn("pot_border")}></div>
              <div className={cn("pot_border_fill")}></div>
            </div>

            <div className={cn("app_nav_device_margin")}></div>
          </div>
        </>
      )
    } else {
      return null
    }
  }
}

const cn = classNames.bind(styles)

const mapStateToProps = (state) => {
  return {
    showNavigation: state.common.showNavigation,
    currentPage: state.common.currentPage,
  }
}

export default connect(mapStateToProps)(withRouter(Navigation))
