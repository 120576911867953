import { createStore, combineReducers, applyMiddleware, compose } from "redux"
import ReduxThunk from "redux-thunk"

import commonReducer from "./common/index.js"
import detailReducer from "./detail"
import authReducer from "./auth"
import searchResultReducer from "./search_result"
import tutorialReducer from "./tutorial"

const reducers = combineReducers({
  common: commonReducer,
  detail: detailReducer,
  auth: authReducer,
  search_result: searchResultReducer,
  tutorial: tutorialReducer,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store =
  process.env.REACT_APP_ENV === "dev"
    ? createStore(reducers, /* preloadedState, */ composeEnhancers(applyMiddleware(ReduxThunk)))
    : createStore(reducers, applyMiddleware(ReduxThunk))

export default store
