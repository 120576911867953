import React from "react";

import classNames from "classnames/bind";
import styles from "../../styles/Gnb.module.scss";

export class Gnb extends React.Component {
  render() {
    return (
      <div className={cn("test-gnb")}>
        <div className={cn("gnb-btn")}>
          <div className={cn("gnb-btn-img")}></div>
        </div>
        <div className={cn("gnb-btn")}>
          <div className={cn("gnb-btn-img")}></div>
        </div>
        <div className={cn("gnb-btn")}>
          <div className={cn("gnb-btn-img")}></div>
        </div>
      </div>
    );
  }
}

const cn = classNames.bind(styles);

export default Gnb;
