import React from "react"

import classNames from "classnames/bind"
import styles from "../../styles/Detail_n.module.scss"

import { connect } from "react-redux"
import DiscountTagItem from "./DiscountTagItem"

class DiscountTags extends React.Component {
  render() {
    const { discountTags } = this.props

    return (
      <div className={cn("discount_tags")}>
        {discountTags.map((item, index) => (
          <DiscountTagItem tagName={item} key={index} />
        ))}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  discountTags: ["GS25 1+1", "CU 1+1"],
})

const cn = classNames.bind(styles)

export default connect(mapStateToProps)(DiscountTags)
