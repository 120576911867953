import axios from "axios"

const axiosInstance = axios.create({
  withCredentials: true,
})

const APIMethods = ["get", "put", "post", "delete"]

const baseAPI = {}

APIMethods.forEach((item) => (baseAPI[item] = (URL, data) => axiosInstance[item](URL, data)))

export default baseAPI
