const api = {
  getProductInfo: {
    id: "000000",
    name_kor: "이름",
    brandname: "브랜드이름",
    image_front_250: null,
    image_front_1000: null,
  },
}

export default api
