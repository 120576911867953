import React from "react"

import classNames from "classnames/bind"
import styles from "../../styles/Ranking.module.scss"

const RankingItem = ({ ranking }) => {
  return (
    <div className={cn("ranking_item")}>
      <div className={cn("ranking_num")}>{ranking}</div>
      <div className={cn("product_img")}>
        <img src="/images/banana.png" alt="" />
      </div>
      <div className={cn("product_info")}>
        <div className={cn("brand")}>
          <div className={cn("manufacturer")}>오리온</div>
          <div className={cn("brand_line")}>닥터유</div>
        </div>
        <div className={cn("product_name")}>허니버터칩 슈크림메이플맛</div>
      </div>
      <div className={cn("ranking_trophy")}></div>
      <div className={cn("product_rating")}>
        <img src="/images/star_full.svg" alt="" />
        <div className={cn("rating_text")}>4.5</div>
      </div>
    </div>
  )
}

const cn = classNames.bind(styles)

export default RankingItem
