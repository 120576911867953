import React from "react"
import classNames from "classnames/bind"
import styles from "../../../styles/SearchResult_n.module.scss"

import { thousandSeparator } from "../../../util"
import { getActualProductName } from "../../../util/index"

import { connect } from "react-redux"

import { withRouter } from "react-router-dom"
import { setScrollTop } from "../../../store/search_result"

import RatingStarImages from "../../common/RatingStarImages"

class Item extends React.Component {
  loadProductDetail = () => {
    const { dispatch, history, productInfo } = this.props
    const scrollY = document.getElementById("wrapper").scrollTop

    dispatch(setScrollTop(scrollY))
    history.push(`/detail/${productInfo.productID}`)
  }

  render() {
    const { productInfo } = this.props
    const actualProductName = getActualProductName(productInfo.nameKor, productInfo.flavor, productInfo.flavorPosition)

    return (
      <div className={cn("list_item")} onClick={this.loadProductDetail}>
        <div className={cn("item_image")}>
          <img src={productInfo.image_front_300 || productInfo.image_front} alt="" />
        </div>

        <div className={cn("item_brand_info")}>
          <div className={cn("manufacturer")}>{productInfo.manufacturer}</div>
          {productInfo.brandLine && <div className={cn("brand_line")}>{productInfo.brandLine}</div>}
        </div>
        <div className={cn("item_name")}>{actualProductName}</div>
        {productInfo.price && <div className={cn("item_price")}>{thousandSeparator(productInfo.price) + "원"}</div>}
        <div className={cn("item_rating")}>
          <RatingStarImages rating={productInfo.rating} />
        </div>
      </div>
    )
  }
}

const cn = classNames.bind(styles)

export default connect()(withRouter(Item))
