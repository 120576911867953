import React, { Component } from "react"

import classNames from "classnames/bind"
import styles from "../../styles/_common.scss"

import { withRouter } from "react-router-dom"

export class BackButton extends Component {
  goBack = () => {
    this.props.history.goBack()
  }

  render() {
    return (
      <div className={cn("back_btn")} onClick={this.goBack}>
        <img src="/images/back_btn.svg" alt="" />
      </div>
    )
  }
}

const cn = classNames.bind(styles)

export default withRouter(BackButton)
