import React from "react"
import ResultItems from "./ResultItems"

import SearchBar from "../SearchBar"
import ResultOrder from "./ResultOrder"
import ResultCount from "./ResultCount"

const SearchResult = () => {
  return (
    <>
      <SearchBar />
      <ResultCount />
      <ResultOrder />
      <ResultItems />
    </>
  )
}

export default SearchResult
