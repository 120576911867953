import React from "react"

import classNames from "classnames/bind"
import styles from "../../../../styles/RecentKeywords.module.scss"

import { deleteRecentKeywordInCookie } from "../../../../util/recentKeywords"
import { Link } from "react-router-dom"

class IndividualKeyword extends React.Component {
  deleteRecentKeyword = keyword => {
    deleteRecentKeywordInCookie(keyword)
    this.props.deleteRecentKeyword(keyword)
  }

  render() {
    const { value } = this.props

    return (
      <div className={cn("recent_keyword")}>
        <div className={cn("search_btn")}>
          <img src="/images/recent_search.svg" alt="" />
        </div>
        <Link to={"/search/" + value}>
          <div className={cn("keyword_text")}>{value}</div>
        </Link>
        <div
          className={cn("delete_btn")}
          onClick={() => {
            this.deleteRecentKeyword(value)
          }}
        >
          <img src="/images/recent_delete_btn.svg" alt="검색어 삭제" />
        </div>
      </div>
    )
  }
}

const cn = classNames.bind(styles)

export default IndividualKeyword
