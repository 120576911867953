import React from "react"

import classNames from "classnames/bind"
import styles from "../../../../styles/NewComment.module.scss"
import { connect } from "react-redux"

import api from "../../../../api/index"

import star_full from "../../../../assets/star_full.svg"
import { getProductInfo, getProductEvaluationInfo } from "../../../../store/detail"
import ProductEvaluation from "../../ProductEvaluation"

class ProductInfoPreview extends React.Component {
  render() {
    const { name_kor, brandname, image_front_250 } = this.props.productInfo
    const { rating } = this.props.productEvaluationInfo

    return (
      <div className={cn("product_info")}>
        <div className={cn("product_info_image")}>
          <img src={image_front_250} alt="" />
        </div>
        <div className={cn("product_info_text")}>
          <div className={cn("product_name")}>{name_kor}</div>
          <div className={cn("brand_name")}>{brandname}</div>
          <div className={cn("rating")}>
            <img src={star_full} alt="" />
            <span>{rating}</span>
          </div>
        </div>
      </div>
    )
  }
}

const cn = classNames.bind(styles)

const mapStateToProps = (state, ownProps) => ({
  productInfo: state.detail.productInfo,
  productEvaluationInfo: state.detail.productEvaluationInfo,
})

export default connect(mapStateToProps)(ProductInfoPreview)
