/* eslint-disable react/jsx-no-target-blank */
import React from "react"

import classNames from "classnames/bind"
import styles from "../../../styles/Main.module.scss"

const cn = classNames.bind(styles)

class ChoiceContent extends React.Component {
  render() {
    const { imgUrl, webUrl } = this.props

    return (
      <div className={cn("choice_content")}>
        <a href={webUrl} target="_blank">
          <img src={imgUrl} style={{ maxWidth: "100%", maxHeight: "100%" }} alt="" />
        </a>
      </div>
    )
  }
}

export default ChoiceContent
