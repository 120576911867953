import React from "react"

import classNames from "classnames/bind"
import styles from "../../../styles/Main.module.scss"

import ReviewContent from "./ReviewContent"

const cn = classNames.bind(styles)

class ReviewSlider extends React.Component {
  render() {
    return (
      <div className={cn("review_slider")}>
        <ReviewContent />
        <ReviewContent />
        <ReviewContent />
        <ReviewContent />
        <ReviewContent />
      </div>
    )
  }
}

export default ReviewSlider
