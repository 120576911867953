import React from "react"

import classNames from "classnames/bind"
import styles from "../../../styles/Main.module.scss"

import api from "../../../api/index"

import RankingIndividualItem from "./RankingIndividualItem"

class RankingItems extends React.Component {
  state = {
    top3: [{}, {}, {}],
  }

  getRankingTop3FromApi = () => {
    // api
    //   .getRankingTop3()
    //   .then(result => {
    //     return Promise.all([api.getProductInfo(result.data[0]), api.getProductInfo(result.data[1]), api.getProductInfo(result.data[2])])
    //   })
    //   .then(result => {
    //     this.setState({ top3: result.map((item, index) => item.data[0]) })
    //   })
  }

  componentDidMount() {
    this.getRankingTop3FromApi()
  }

  render() {
    return (
      <ul className={cn("ranking_page")}>
        <RankingIndividualItem rank="1" productInfo={this.state.top3[0]} />
        <RankingIndividualItem rank="2" productInfo={this.state.top3[1]} />
        <RankingIndividualItem rank="3" productInfo={this.state.top3[2]} />
        <RankingIndividualItem rank="4" productInfo={this.state.top3[2]} />
        <RankingIndividualItem rank="5" productInfo={this.state.top3[2]} />
      </ul>
    )
  }
}

const cn = classNames.bind(styles)

export default RankingItems
