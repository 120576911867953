import createReducer from "../common"
import DETAIL_INITIAL_STATE from "./state"

const id = "common"

const SET_SHOW_NAVIGATION = `${id}/SET_SHOW_NAVIGATION`
const SET_CURRENT_PAGE = `${id}/SET_CURRENT_PAGE`
const SET_SHOW_MODAL = `${id}/SET_SHOW_MODAL`

export const setShowNavigation = value => ({ type: SET_SHOW_NAVIGATION, value })
export const setCurrentPage = page => ({ type: SET_CURRENT_PAGE, page })
export const setShowModal = (showModal, modalComponent) => ({ type: SET_SHOW_MODAL, showModal, modalComponent })

const reducer = createReducer(DETAIL_INITIAL_STATE, {
  [SET_SHOW_NAVIGATION]: (state, action) => (state.navigation.showNavigation = action.value),
  [SET_CURRENT_PAGE]: (state, action) => (state.navigation.currentPage = action.page),
  [SET_SHOW_MODAL]: (state, action) => {
    state.modal.modalComponent = action.showModal ? action.modalComponent : ""
    state.modal.showModal = action.showModal
  },
})

export default reducer
