import { PAGES } from "../../constants/index"

const INITIAL_STATE = {
  navigation: {
    showNavigation: true,
    currentPage: PAGES.HOME,
  },

  modal: {
    showModal: false,
    modalComponent: "",
  },
}

export default INITIAL_STATE
