import React from "react"

import classNames from "classnames/bind"
import styles from "../../../../styles/RecentKeywords.module.scss"

import {
  deleteAllRecentKeywordsInCookie,
  getRecentKeywordsFromCookie,
} from "../../../../util/recentKeywords"
import IndividualKeyword from "./IndividualKeyword"

class RecentKeywords extends React.Component {
  state = {
    recentKeywords: [],
  }

  //needs modification
  deleteRecentKeyword = keyword => {
    let { recentKeywords } = this.state
    const index = recentKeywords.indexOf(keyword)

    if (index > -1) recentKeywords.splice(index, 1)

    this.setState({ recentKeywords })
  }

  deleteAllRecentKeywords = () => {
    this.setState({ recentKeywords: [] })
    deleteAllRecentKeywordsInCookie()
  }

  componentDidMount() {
    const keywordsInCookie = getRecentKeywordsFromCookie()

    if (keywordsInCookie && keywordsInCookie.length > 0) {
      this.setState({ recentKeywords: keywordsInCookie })
    }
  }

  render() {
    const { recentKeywords } = this.state

    return (
      <div className={cn("recent_keywords")}>
        <div className={cn("title")}>
          <div className={cn("title_txt")}>최근 검색어</div>
          <div
            className={cn("delete_btn")}
            onClick={this.deleteAllRecentKeywords}
          >
            모두 삭제
          </div>
        </div>
        <div className={cn("recent_keyword_list")}>
          {recentKeywords.map((item, index) => (
            <IndividualKeyword
              value={item}
              key={index}
              deleteRecentKeyword={this.deleteRecentKeyword}
            />
          ))}
        </div>
      </div>
    )
  }
}

const cn = classNames.bind(styles)

export default RecentKeywords
