import React from "react"

// import { BrowserView, MobileView, isBrowser, isMobile, mobileModel, getUA } from "react-device-detect"

const DeviceMargin = props => {
  const customHeight = {
    width: "100%",
    height: "calc(env(safe-area-inset-bottom) * 6 / 10)",
  }

  return <div style={customHeight}></div>
}

export default DeviceMargin
