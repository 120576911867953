const productList = [
  {
    productID: 1,
    productName: "죠리퐁",
    brandName: "크라운",
    criteriaWeight: 100,
    kcal: 445,
    sodium: 170,
    carbohydrate: 77,
    sugar: 31,
    fat: 13,
    transfat: 0,
    saturatedFat: 6,
    cholesterol: 0,
    protein: 8,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 2,
    productName: "못말리는 신짱",
    brandName: "크라운",
    criteriaWeight: 73,
    kcal: 384,
    sodium: 220,
    carbohydrate: 47,
    sugar: 16,
    fat: 20,
    transfat: 0,
    saturatedFat: 6,
    cholesterol: 0,
    protein: 4,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 3,
    productName: "칸쵸",
    brandName: "롯데",
    criteriaWeight: 54,
    kcal: 260,
    sodium: 95,
    carbohydrate: 36,
    sugar: 17,
    fat: 11,
    transfat: 0,
    saturatedFat: 7,
    cholesterol: 0,
    protein: 4,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 4,
    productName: "프링글스 오리지널",
    brandName: "켈로그",
    criteriaWeight: 100,
    kcal: 523,
    sodium: 469,
    carbohydrate: 62.8,
    sugar: 0,
    fat: 27.9,
    transfat: 0,
    saturatedFat: 12.3,
    cholesterol: 0,
    protein: 5.1,
    cluster: 8,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 5,
    productName: "꼬북칩 콘스프맛",
    brandName: "오리온",
    criteriaWeight: 30,
    kcal: 175,
    sodium: 210,
    carbohydrate: 18,
    sugar: 2,
    fat: 11,
    transfat: 0,
    saturatedFat: 4.1,
    cholesterol: 0,
    protein: 1,
    cluster: 2,
    spicy: 0,
    sweety: 0,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 6,
    productName: "버터와플",
    brandName: "크라운",
    criteriaWeight: 26.34,
    kcal: 125,
    sodium: 80,
    carbohydrate: 20,
    sugar: 8,
    fat: 4.3,
    transfat: 0.1,
    saturatedFat: 3.1,
    cholesterol: 20,
    protein: 2,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 7,
    productName: "계란과자",
    brandName: "해태",
    criteriaWeight: 33,
    kcal: 150,
    sodium: 70,
    carbohydrate: 24,
    sugar: 11,
    fat: 5,
    transfat: 0,
    saturatedFat: 2.5,
    cholesterol: 15,
    protein: 2.1,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 8,
    productName: "포카칩 오리지널",
    brandName: "오리온",
    criteriaWeight: 30,
    kcal: 167,
    sodium: 115,
    carbohydrate: 15,
    sugar: 0.4,
    fat: 11,
    transfat: 0.1,
    saturatedFat: 4.1,
    cholesterol: 0,
    protein: 2,
    cluster: 3,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 9,
    productName: "알새우칩",
    brandName: "농심",
    criteriaWeight: 35,
    kcal: 180,
    sodium: 270,
    carbohydrate: 24,
    sugar: 3,
    fat: 9,
    transfat: 0,
    saturatedFat: 2.8,
    cholesterol: 0,
    protein: 1,
    cluster: 7,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 10,
    productName: "웨하스 바닐라맛",
    brandName: "롯데",
    criteriaWeight: 60,
    kcal: 310,
    sodium: 95,
    carbohydrate: 43,
    sugar: 21,
    fat: 14,
    transfat: 0,
    saturatedFat: 10,
    cholesterol: 5,
    protein: 3,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 11,
    productName: "빠다코코낫",
    brandName: "롯데",
    criteriaWeight: 50,
    kcal: 250,
    sodium: 130,
    carbohydrate: 33,
    sugar: 11,
    fat: 12,
    transfat: 0,
    saturatedFat: 8,
    cholesterol: 5,
    protein: 3,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 12,
    productName: "후레쉬베리",
    brandName: "오리온",
    criteriaWeight: 28,
    kcal: 125,
    sodium: 50,
    carbohydrate: 15,
    sugar: 9,
    fat: 7,
    transfat: 0,
    saturatedFat: 3.4,
    cholesterol: 16,
    protein: 1,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 1,
  },
  {
    productID: 13,
    productName: "콘칲",
    brandName: "크라운",
    criteriaWeight: 85,
    kcal: 448,
    sodium: 450,
    carbohydrate: 54,
    sugar: 2,
    fat: 24,
    transfat: 0.2,
    saturatedFat: 8,
    cholesterol: 0,
    protein: 4,
    cluster: 8,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 14,
    productName: "맛동산",
    brandName: "해태",
    criteriaWeight: 30,
    kcal: 150,
    sodium: 15,
    carbohydrate: 19,
    sugar: 7,
    fat: 7,
    transfat: 0,
    saturatedFat: 1.5,
    cholesterol: 0,
    protein: 3,
    cluster: 5,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 15,
    productName: "허니버터칩",
    brandName: "해태",
    criteriaWeight: 60,
    kcal: 350,
    sodium: 210,
    carbohydrate: 30,
    sugar: 3,
    fat: 24,
    transfat: 0,
    saturatedFat: 8,
    cholesterol: 0,
    protein: 3,
    cluster: 3,
    spicy: 0,
    sweety: 1,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 16,
    productName: "홈런볼",
    brandName: "해태",
    criteriaWeight: 100,
    kcal: 580,
    sodium: 150,
    carbohydrate: 51,
    sugar: 32,
    fat: 38,
    transfat: 0.5,
    saturatedFat: 7,
    cholesterol: 75,
    protein: 8,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 17,
    productName: "오징어땅콩",
    brandName: "오리온",
    criteriaWeight: 30,
    kcal: 147,
    sodium: 100,
    carbohydrate: 17,
    sugar: 6,
    fat: 7,
    transfat: 0,
    saturatedFat: 1.7,
    cholesterol: 0.7,
    protein: 4,
    cluster: 4,
    spicy: 0,
    sweety: 0,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 18,
    productName: "썬칩",
    brandName: "오리온",
    criteriaWeight: 30,
    kcal: 156,
    sodium: 130,
    carbohydrate: 19,
    sugar: 2,
    fat: 8,
    transfat: 0,
    saturatedFat: 3.2,
    cholesterol: 0,
    protein: 2,
    cluster: 3,
    spicy: 1,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 19,
    productName: "쌀로별",
    brandName: "롯데",
    criteriaWeight: 100,
    kcal: 550,
    sodium: 400,
    carbohydrate: 56,
    sugar: 27,
    fat: 32,
    transfat: 0,
    saturatedFat: 15,
    cholesterol: 0,
    protein: 9,
    cluster: 3,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 20,
    productName: "국희 땅콩샌드",
    brandName: "크라운",
    criteriaWeight: 24,
    kcal: 120,
    sodium: 60,
    carbohydrate: 15,
    sugar: 6,
    fat: 6,
    transfat: 0,
    saturatedFat: 3.2,
    cholesterol: 0,
    protein: 2,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 21,
    productName: "쵸코하임",
    brandName: "크라운",
    criteriaWeight: 47,
    kcal: 245,
    sodium: 75,
    carbohydrate: 27,
    sugar: 15,
    fat: 14,
    transfat: 0,
    saturatedFat: 7.6,
    cholesterol: 5,
    protein: 4,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 22,
    productName: "꿀꽈배기",
    brandName: "농심",
    criteriaWeight: 45,
    kcal: 225,
    sodium: 115,
    carbohydrate: 32,
    sugar: 10,
    fat: 10,
    transfat: 0,
    saturatedFat: 3.3,
    cholesterol: 0,
    protein: 1.4,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 23,
    productName: "카라멜콘땅콩",
    brandName: "크라운",
    criteriaWeight: 64,
    kcal: 335,
    sodium: 140,
    carbohydrate: 42,
    sugar: 13,
    fat: 17,
    transfat: 0,
    saturatedFat: 4.8,
    cholesterol: 0,
    protein: 4,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 24,
    productName: "카스타드",
    brandName: "롯데",
    criteriaWeight: 23,
    kcal: 102,
    sodium: 40,
    carbohydrate: 11,
    sugar: 6,
    fat: 6,
    transfat: 0,
    saturatedFat: 2.6,
    cholesterol: 28,
    protein: 1,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 25,
    productName: "오감자 감자그라탕",
    brandName: "오리온",
    criteriaWeight: 50,
    kcal: 292,
    sodium: 260,
    carbohydrate: 28,
    sugar: 1,
    fat: 18,
    transfat: 0,
    saturatedFat: 7,
    cholesterol: 0,
    protein: 2,
    cluster: 8,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 26,
    productName: "수미칩",
    brandName: "농심",
    criteriaWeight: 30,
    kcal: 150,
    sodium: 120,
    carbohydrate: 18,
    sugar: 1,
    fat: 8,
    transfat: 0,
    saturatedFat: 2.6,
    cholesterol: 0,
    protein: 2,
    cluster: 3,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 27,
    productName: "양파링",
    brandName: "농심",
    criteriaWeight: 40,
    kcal: 190,
    sodium: 260,
    carbohydrate: 27,
    sugar: 2.5,
    fat: 9,
    transfat: 0,
    saturatedFat: 2.7,
    cholesterol: 0,
    protein: 2,
    cluster: 2,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 28,
    productName: "스윙칩 볶음고추장맛",
    brandName: "오리온",
    criteriaWeight: 60,
    kcal: 347,
    sodium: 220,
    carbohydrate: 32,
    sugar: 2,
    fat: 23,
    transfat: 0.5,
    saturatedFat: 7,
    cholesterol: 0,
    protein: 3,
    cluster: 3,
    spicy: 1,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 29,
    productName: "고래밥 볶음양념맛",
    brandName: "오리온",
    criteriaWeight: 40,
    kcal: 186,
    sodium: 280,
    carbohydrate: 30,
    sugar: 5,
    fat: 6,
    transfat: 0,
    saturatedFat: 3.1,
    cholesterol: 0,
    protein: 3,
    cluster: 2,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 30,
    productName: "고구마깡",
    brandName: "농심",
    criteriaWeight: 40,
    kcal: 195,
    sodium: 200,
    carbohydrate: 29,
    sugar: 10,
    fat: 8,
    transfat: 0,
    saturatedFat: 2.7,
    cholesterol: 0,
    protein: 2,
    cluster: 8,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 31,
    productName: "새우깡",
    brandName: "농심",
    criteriaWeight: 45,
    kcal: 225,
    sodium: 260,
    carbohydrate: 29,
    sugar: 2,
    fat: 11,
    transfat: 0,
    saturatedFat: 3.6,
    cholesterol: 0,
    protein: 3,
    cluster: 0,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 32,
    productName: "치토스 매콤달콤한맛",
    brandName: "롯데",
    criteriaWeight: 30,
    kcal: 170,
    sodium: 115,
    carbohydrate: 18,
    sugar: 1,
    fat: 10,
    transfat: 0,
    saturatedFat: 4.7,
    cholesterol: 0,
    protein: 2,
    cluster: 3,
    spicy: 1,
    sweety: 1,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 33,
    productName: "예감 오리지널",
    brandName: "오리온",
    criteriaWeight: 16,
    kcal: 76,
    sodium: 100,
    carbohydrate: 10,
    sugar: 2,
    fat: 4,
    transfat: 0.1,
    saturatedFat: 2,
    cholesterol: 0,
    protein: 1,
    cluster: 0,
    spicy: 1,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 34,
    productName: "오레오",
    brandName: "동서",
    criteriaWeight: 50,
    kcal: 250,
    sodium: 200,
    carbohydrate: 35,
    sugar: 19,
    fat: 11.5,
    transfat: 0,
    saturatedFat: 6.5,
    cholesterol: 0,
    protein: 2,
    cluster: 3,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 35,
    productName: "마켓오 리얼브라우니",
    brandName: "오리온",
    criteriaWeight: 20,
    kcal: 100,
    sodium: 25,
    carbohydrate: 10,
    sugar: 6,
    fat: 6,
    transfat: 0,
    saturatedFat: 3.9,
    cholesterol: 10,
    protein: 1,
    cluster: 5,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 36,
    productName: "마가렛트",
    brandName: "롯데",
    criteriaWeight: 22,
    kcal: 110,
    sodium: 60,
    carbohydrate: 13,
    sugar: 6,
    fat: 6,
    transfat: 0.2,
    saturatedFat: 3,
    cholesterol: 5,
    protein: 1,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 37,
    productName: "에이스",
    brandName: "해태",
    criteriaWeight: 121,
    kcal: 650,
    sodium: 590,
    carbohydrate: 73,
    sugar: 9,
    fat: 36,
    transfat: 0.8,
    saturatedFat: 19,
    cholesterol: 5,
    protein: 8,
    cluster: 8,
    spicy: 0,
    sweety: 0,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 38,
    productName: "인디안밥",
    brandName: "농심",
    criteriaWeight: 40,
    kcal: 215,
    sodium: 260,
    carbohydrate: 25,
    sugar: 1,
    fat: 12,
    transfat: 0,
    saturatedFat: 4.2,
    cholesterol: 0,
    protein: 2,
    cluster: 2,
    spicy: 0,
    sweety: 0,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 39,
    productName: "꼬깔콘 고소한맛",
    brandName: "롯데",
    criteriaWeight: 100,
    kcal: 570,
    sodium: 480,
    carbohydrate: 59,
    sugar: 5,
    fat: 35,
    transfat: 0,
    saturatedFat: 12,
    cholesterol: 0,
    protein: 5,
    cluster: 8,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 40,
    productName: "쿠크다스",
    brandName: "크라운",
    criteriaWeight: 32,
    kcal: 185,
    sodium: 65,
    carbohydrate: 19,
    sugar: 12,
    fat: 11,
    transfat: 0,
    saturatedFat: 7,
    cholesterol: 5,
    protein: 2,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 41,
    productName: "칙촉 오리지널",
    brandName: "롯데",
    criteriaWeight: 90,
    kcal: 440,
    sodium: 200,
    carbohydrate: 55,
    sugar: 30,
    fat: 22,
    transfat: 0.5,
    saturatedFat: 14,
    cholesterol: 30,
    protein: 6,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 42,
    productName: "참쌀선과",
    brandName: "크라운",
    criteriaWeight: 100,
    kcal: 485,
    sodium: 600,
    carbohydrate: 73,
    sugar: 14,
    fat: 19,
    transfat: 0,
    saturatedFat: 9,
    cholesterol: 0,
    protein: 5,
    cluster: 0,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 43,
    productName: "닥터유 다이제",
    brandName: "오리온",
    criteriaWeight: 52,
    kcal: 251,
    sodium: 340,
    carbohydrate: 37,
    sugar: 9,
    fat: 11,
    transfat: 0.5,
    saturatedFat: 6,
    cholesterol: 0,
    protein: 3,
    cluster: 2,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 44,
    productName: "초코픽",
    brandName: "해태",
    criteriaWeight: 45,
    kcal: 130,
    sodium: 130,
    carbohydrate: 31,
    sugar: 14,
    fat: 9,
    transfat: 0,
    saturatedFat: 3.7,
    cholesterol: 0,
    protein: 4,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 45,
    productName: "신당동 떡볶이",
    brandName: "해태",
    criteriaWeight: 30,
    kcal: 150,
    sodium: 130,
    carbohydrate: 19,
    sugar: 9,
    fat: 8,
    transfat: 0,
    saturatedFat: 2.7,
    cholesterol: 0,
    protein: 1,
    cluster: 3,
    spicy: 1,
    sweety: 1,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 46,
    productName: "치킨팝 닭강정맛",
    brandName: "오리온",
    criteriaWeight: 100,
    kcal: 524,
    sodium: 430,
    carbohydrate: 65,
    sugar: 28,
    fat: 28,
    transfat: 0,
    saturatedFat: 11,
    cholesterol: 0,
    protein: 3,
    cluster: 3,
    spicy: 1,
    sweety: 1,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 47,
    productName: "롯데샌드 오리지널",
    brandName: "롯데",
    criteriaWeight: 50,
    kcal: 260,
    sodium: 110,
    carbohydrate: 33,
    sugar: 13,
    fat: 13,
    transfat: 0,
    saturatedFat: 7,
    cholesterol: 5,
    protein: 2,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 1,
  },
  {
    productID: 48,
    productName: "촉촉한 초코칩",
    brandName: "오리온",
    criteriaWeight: 20,
    kcal: 101,
    sodium: 50,
    carbohydrate: 11,
    sugar: 6,
    fat: 6,
    transfat: 0.5,
    saturatedFat: 3.8,
    cholesterol: 5,
    protein: 1,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 49,
    productName: "오잉",
    brandName: "롯데",
    criteriaWeight: 100,
    kcal: 505,
    sodium: 690,
    carbohydrate: 69,
    sugar: 7,
    fat: 23,
    transfat: 0,
    saturatedFat: 11,
    cholesterol: 0,
    protein: 4.5,
    cluster: 2,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 50,
    productName: "엄마손 파이",
    brandName: "롯데",
    criteriaWeight: 100,
    kcal: 495,
    sodium: 240,
    carbohydrate: 57,
    sugar: 25,
    fat: 24,
    transfat: 0,
    saturatedFat: 12,
    cholesterol: 5,
    protein: 11,
    cluster: 1,
    spicy: 0,
    sweety: 0,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 51,
    productName: "포스틱",
    brandName: "농심",
    criteriaWeight: 40,
    kcal: 185,
    sodium: 250,
    carbohydrate: 29,
    sugar: 1,
    fat: 7,
    transfat: 0,
    saturatedFat: 2.4,
    cholesterol: 0,
    protein: 2,
    cluster: 0,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 52,
    productName: "빠새!",
    brandName: "해태",
    criteriaWeight: 30,
    kcal: 180,
    sodium: 180,
    carbohydrate: 14,
    sugar: 2,
    fat: 13,
    transfat: 0,
    saturatedFat: 4,
    cholesterol: 5,
    protein: 2,
    cluster: 0,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 53,
    productName: "구운 감자 슬림",
    brandName: "해태",
    criteriaWeight: 27,
    kcal: 130,
    sodium: 210,
    carbohydrate: 19,
    sugar: 2,
    fat: 6,
    transfat: 0,
    saturatedFat: 2,
    cholesterol: 0,
    protein: 1,
    cluster: 7,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 54,
    productName: "자가비",
    brandName: "해태",
    criteriaWeight: 45,
    kcal: 260,
    sodium: 280,
    carbohydrate: 22,
    sugar: 0,
    fat: 18,
    transfat: 0,
    saturatedFat: 5,
    cholesterol: 0,
    protein: 3,
    cluster: 0,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 55,
    productName: "꽃게랑",
    brandName: "빙그레",
    criteriaWeight: 70,
    kcal: 380,
    sodium: 540,
    carbohydrate: 42,
    sugar: 3.2,
    fat: 19.6,
    transfat: 0,
    saturatedFat: 6.3,
    cholesterol: 0,
    protein: 5,
    cluster: 7,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 56,
    productName: "포키 오리지널",
    brandName: "해태",
    criteriaWeight: 42,
    kcal: 220,
    sodium: 140,
    carbohydrate: 28,
    sugar: 14,
    fat: 10,
    transfat: 0,
    saturatedFat: 6,
    cholesterol: 0,
    protein: 4,
    cluster: 4,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 57,
    productName: "닭다리 너겟",
    brandName: "농심",
    criteriaWeight: 35,
    kcal: 175,
    sodium: 200,
    carbohydrate: 22,
    sugar: 1,
    fat: 9,
    transfat: 0,
    saturatedFat: 2.9,
    cholesterol: 0,
    protein: 2,
    cluster: 0,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 58,
    productName: "눈을감자",
    brandName: "오리온",
    criteriaWeight: 56,
    kcal: 291,
    sodium: 390,
    carbohydrate: 36,
    sugar: 0,
    fat: 15,
    transfat: 0,
    saturatedFat: 6,
    cholesterol: 0,
    protein: 3,
    cluster: 2,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 59,
    productName: "사또밥",
    brandName: "삼양",
    criteriaWeight: 67,
    kcal: 370,
    sodium: 210,
    carbohydrate: 38,
    sugar: 6,
    fat: 23,
    transfat: 0,
    saturatedFat: 8,
    cholesterol: 66,
    protein: 3,
    cluster: 4,
    spicy: 0,
    sweety: 0,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 60,
    productName: "초코파이",
    brandName: "오리온",
    criteriaWeight: 39,
    kcal: 171,
    sodium: 90,
    carbohydrate: 25,
    sugar: 14,
    fat: 7,
    transfat: 0,
    saturatedFat: 4.1,
    cholesterol: 0,
    protein: 2,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 61,
    productName: "오뜨 치즈맛",
    brandName: "오리온",
    criteriaWeight: 24,
    kcal: 110,
    sodium: 75,
    carbohydrate: 12,
    sugar: 7,
    fat: 6,
    transfat: 0.06,
    saturatedFat: 3.1,
    cholesterol: 18,
    protein: 2,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 62,
    productName: "씨리얼",
    brandName: "롯데",
    criteriaWeight: 28,
    kcal: 145,
    sodium: 60,
    carbohydrate: 17,
    sugar: 10,
    fat: 7,
    transfat: 0,
    saturatedFat: 5,
    cholesterol: 0,
    protein: 2,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 63,
    productName: "칸츄리콘",
    brandName: "해태",
    criteriaWeight: 30,
    kcal: 175,
    sodium: 240,
    carbohydrate: 16,
    sugar: 3,
    fat: 12,
    transfat: 0,
    saturatedFat: 3.9,
    cholesterol: 0,
    protein: 1,
    cluster: 7,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 64,
    productName: "쟈키쟈키 숯불갈비맛",
    brandName: "빙그레",
    criteriaWeight: 30,
    kcal: 160,
    sodium: 180,
    carbohydrate: 18,
    sugar: 2,
    fat: 9,
    transfat: 0,
    saturatedFat: 3,
    cholesterol: 0,
    protein: 1.6,
    cluster: 0,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 65,
    productName: "무뚝뚝 감자칩 통후추 소금맛",
    brandName: "오리온",
    criteriaWeight: 30,
    kcal: 166,
    sodium: 220,
    carbohydrate: 17,
    sugar: 1,
    fat: 10,
    transfat: 0,
    saturatedFat: 2.9,
    cholesterol: 0,
    protein: 2,
    cluster: 2,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 66,
    productName: "화이트 하임",
    brandName: "크라운",
    criteriaWeight: 47,
    kcal: 255,
    sodium: 60,
    carbohydrate: 27,
    sugar: 18,
    fat: 15,
    transfat: 0,
    saturatedFat: 8,
    cholesterol: 5,
    protein: 3,
    cluster: 5,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 67,
    productName: "크럼블 화이트",
    brandName: "크라운",
    criteriaWeight: 32,
    kcal: 150,
    sodium: 55,
    carbohydrate: 18,
    sugar: 10,
    fat: 8,
    transfat: 0,
    saturatedFat: 6,
    cholesterol: 5,
    protein: 2,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 68,
    productName: "자갈치",
    brandName: "농심",
    criteriaWeight: 45,
    kcal: 235,
    sodium: 260,
    carbohydrate: 29,
    sugar: 3,
    fat: 12,
    transfat: 0,
    saturatedFat: 3.6,
    cholesterol: 0,
    protein: 3,
    cluster: 0,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 69,
    productName: "양파깡",
    brandName: "농심",
    criteriaWeight: 30,
    kcal: 150,
    sodium: 170,
    carbohydrate: 19,
    sugar: 1.8,
    fat: 8,
    transfat: 0,
    saturatedFat: 2.7,
    cholesterol: 0,
    protein: 1,
    cluster: 0,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 70,
    productName: "스모키 베이컨칩",
    brandName: "빙그레",
    criteriaWeight: 70,
    kcal: 368,
    sodium: 500,
    carbohydrate: 42,
    sugar: 2,
    fat: 20,
    transfat: 0,
    saturatedFat: 7,
    cholesterol: 0,
    protein: 5,
    cluster: 2,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 71,
    productName: "감자깡",
    brandName: "농심",
    criteriaWeight: 35,
    kcal: 170,
    sodium: 170,
    carbohydrate: 25,
    sugar: 1.3,
    fat: 7,
    transfat: 0,
    saturatedFat: 2.1,
    cholesterol: 0,
    protein: 2,
    cluster: 8,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 72,
    productName: "오예스",
    brandName: "해태",
    criteriaWeight: 30,
    kcal: 150,
    sodium: 60,
    carbohydrate: 15,
    sugar: 10,
    fat: 9,
    transfat: 0.1,
    saturatedFat: 5,
    cholesterol: 15,
    protein: 2,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 73,
    productName: "롤리폴리",
    brandName: "해태",
    criteriaWeight: 196,
    kcal: 45,
    sodium: 20,
    carbohydrate: 7,
    sugar: 5,
    fat: 1.6,
    transfat: 0.05,
    saturatedFat: 1.1,
    cholesterol: 1.3,
    protein: 1,
    cluster: 5,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 74,
    productName: "츄러스",
    brandName: "크라운",
    criteriaWeight: 40,
    kcal: 215,
    sodium: 130,
    carbohydrate: 25,
    sugar: 10,
    fat: 12,
    transfat: 0,
    saturatedFat: 3.8,
    cholesterol: 0,
    protein: 2,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 75,
    productName: "요하이",
    brandName: "롯데",
    criteriaWeight: 37,
    kcal: 190,
    sodium: 140,
    carbohydrate: 23,
    sugar: 9,
    fat: 10,
    transfat: 0,
    saturatedFat: 6,
    cholesterol: 5,
    protein: 2,
    cluster: 3,
    spicy: 0,
    sweety: 0,
    salty: 0,
    savory: 1,
    sour: 1,
  },
  {
    productID: 76,
    productName: "아이비",
    brandName: "해태",
    criteriaWeight: 23,
    kcal: 100,
    sodium: 160,
    carbohydrate: 16,
    sugar: 1,
    fat: 2.9,
    transfat: 0,
    saturatedFat: 1.8,
    cholesterol: 0,
    protein: 2,
    cluster: 2,
    spicy: 0,
    sweety: 0,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 77,
    productName: "야채타임 + 케첩",
    brandName: "빙그레",
    criteriaWeight: 30,
    kcal: 140,
    sodium: 150,
    carbohydrate: 15,
    sugar: 3,
    fat: 8,
    transfat: 0,
    saturatedFat: 3,
    cholesterol: 0,
    protein: 1.5,
    cluster: 8,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 1,
  },
  {
    productID: 78,
    productName: "허쉬 초콜릿 칩",
    brandName: "허쉬",
    criteriaWeight: 50,
    kcal: 235,
    sodium: 155,
    carbohydrate: 30,
    sugar: 12,
    fat: 11,
    transfat: 0,
    saturatedFat: 5,
    cholesterol: 21,
    protein: 4,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 79,
    productName: "감자 군것질",
    brandName: "농심",
    criteriaWeight: 35,
    kcal: 190,
    sodium: 150,
    carbohydrate: 21,
    sugar: 1,
    fat: 11,
    transfat: 0,
    saturatedFat: 3.5,
    cholesterol: 0,
    protein: 2,
    cluster: 3,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 80,
    productName: "쫄병스낵 매콤한맛",
    brandName: "농심",
    criteriaWeight: 30,
    kcal: 160,
    sodium: 170,
    carbohydrate: 18,
    sugar: 3,
    fat: 9,
    transfat: 0,
    saturatedFat: 4.3,
    cholesterol: 0,
    protein: 2,
    cluster: 0,
    spicy: 1,
    sweety: 1,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 81,
    productName: "초코파이 찰떡",
    brandName: "청우",
    criteriaWeight: 100,
    kcal: 430,
    sodium: 289,
    carbohydrate: 65,
    sugar: 28,
    fat: 17,
    transfat: 0,
    saturatedFat: 6,
    cholesterol: 10,
    protein: 4,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 82,
    productName: "로투스 커피과자",
    brandName: "로투스",
    criteriaWeight: 100,
    kcal: 481,
    sodium: 370,
    carbohydrate: 72.6,
    sugar: 38.1,
    fat: 19,
    transfat: 0,
    saturatedFat: 8.8,
    cholesterol: 0,
    protein: 4.9,
    cluster: 3,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 83,
    productName: "치즈샌드",
    brandName: "크라운",
    criteriaWeight: 30,
    kcal: 150,
    sodium: 180,
    carbohydrate: 20,
    sugar: 4,
    fat: 7,
    transfat: 0,
    saturatedFat: 4.5,
    cholesterol: 0,
    protein: 2,
    cluster: 0,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 1,
  },
  {
    productID: 84,
    productName: "바나나킥",
    brandName: "농심",
    criteriaWeight: 30,
    kcal: 150,
    sodium: 30,
    carbohydrate: 20,
    sugar: 14,
    fat: 7,
    transfat: 0,
    saturatedFat: 6,
    cholesterol: 0,
    protein: 2,
    cluster: 5,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 85,
    productName: "누네띠네",
    brandName: "삼립",
    criteriaWeight: 12,
    kcal: 61,
    sodium: 25,
    carbohydrate: 7,
    sugar: 3,
    fat: 3.4,
    transfat: 0,
    saturatedFat: 1.5,
    cholesterol: 0.4,
    protein: 0.6,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 86,
    productName: "도리토스 나쵸치즈맛",
    brandName: "롯데",
    criteriaWeight: 100,
    kcal: 470,
    sodium: 500,
    carbohydrate: 55,
    sugar: 4,
    fat: 24,
    transfat: 0,
    saturatedFat: 8,
    cholesterol: 0,
    protein: 7,
    cluster: 8,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 87,
    productName: "허니통통",
    brandName: "해태",
    criteriaWeight: 30,
    kcal: 160,
    sodium: 150,
    carbohydrate: 17,
    sugar: 2,
    fat: 10,
    transfat: 0,
    saturatedFat: 3,
    cholesterol: 0,
    protein: 1,
    cluster: 8,
    spicy: 0,
    sweety: 1,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 88,
    productName: "극세 프리츠",
    brandName: "해태",
    criteriaWeight: 47,
    kcal: 225,
    sodium: 620,
    carbohydrate: 30,
    sugar: 4,
    fat: 9,
    transfat: 0,
    saturatedFat: 4,
    cholesterol: 10,
    protein: 6,
    cluster: 6,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 89,
    productName: "포테토칩 사워크림 어니언",
    brandName: "농심",
    criteriaWeight: 60,
    kcal: 330,
    sodium: 260,
    carbohydrate: 35,
    sugar: 2,
    fat: 20,
    transfat: 0,
    saturatedFat: 6,
    cholesterol: 0,
    protein: 3,
    cluster: 3,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 1,
  },
  {
    productID: 90,
    productName: "버터링",
    brandName: "해태",
    criteriaWeight: 86,
    kcal: 470,
    sodium: 210,
    carbohydrate: 49,
    sugar: 18,
    fat: 28,
    transfat: 0,
    saturatedFat: 14,
    cholesterol: 30,
    protein: 6,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 91,
    productName: "부셔먹는 불닭",
    brandName: "삼양",
    criteriaWeight: 90,
    kcal: 425,
    sodium: 790,
    carbohydrate: 65,
    sugar: 3,
    fat: 16,
    transfat: 0,
    saturatedFat: 8,
    cholesterol: 0,
    protein: 8,
    cluster: 7,
    spicy: 1,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 92,
    productName: "땅콩강정",
    brandName: "오리온",
    criteriaWeight: 30,
    kcal: 138,
    sodium: 80,
    carbohydrate: 21,
    sugar: 8,
    fat: 4.7,
    transfat: 0,
    saturatedFat: 0.7,
    cholesterol: 0,
    protein: 3,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 93,
    productName: "몽쉘 생크림케이크",
    brandName: "롯데",
    criteriaWeight: 28,
    kcal: 145,
    sodium: 40,
    carbohydrate: 13,
    sugar: 7,
    fat: 9,
    transfat: 0.1,
    saturatedFat: 6,
    cholesterol: 10,
    protein: 2,
    cluster: 5,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 94,
    productName: "프레첼 갈릭버터맛",
    brandName: "파란푸드",
    criteriaWeight: 30,
    kcal: 135,
    sodium: 240,
    carbohydrate: 22,
    sugar: 1,
    fat: 4,
    transfat: 0,
    saturatedFat: 1.5,
    cholesterol: 0,
    protein: 3,
    cluster: 7,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 95,
    productName: "리츠",
    brandName: "동서",
    criteriaWeight: 40,
    kcal: 210,
    sodium: 270,
    carbohydrate: 23,
    sugar: 3,
    fat: 12,
    transfat: 0,
    saturatedFat: 6,
    cholesterol: 0,
    protein: 2,
    cluster: 2,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 96,
    productName: "별뽀빠이",
    brandName: "삼양",
    criteriaWeight: 30,
    kcal: 140,
    sodium: 95,
    carbohydrate: 22,
    sugar: 5,
    fat: 4.7,
    transfat: 0,
    saturatedFat: 2.3,
    cholesterol: 0,
    protein: 3,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 97,
    productName: "대단한 나쵸",
    brandName: "오리온",
    criteriaWeight: 50,
    kcal: 291,
    sodium: 200,
    carbohydrate: 27,
    sugar: 2,
    fat: 19,
    transfat: 0,
    saturatedFat: 5,
    cholesterol: 0,
    protein: 3,
    cluster: 3,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 98,
    productName: "사브레",
    brandName: "해태",
    criteriaWeight: 35,
    kcal: 170,
    sodium: 65,
    carbohydrate: 25,
    sugar: 10,
    fat: 7,
    transfat: 0,
    saturatedFat: 4.3,
    cholesterol: 5,
    protein: 2,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 99,
    productName: "미니사과쿠키",
    brandName: "삼립",
    criteriaWeight: 16,
    kcal: 70,
    sodium: 40,
    carbohydrate: 11,
    sugar: 6,
    fat: 2.4,
    transfat: 0,
    saturatedFat: 1,
    cholesterol: 5,
    protein: 1,
    cluster: 1,
    spicy: 0,
    sweety: 0,
    salty: 0,
    savory: 1,
    sour: 1,
  },
  {
    productID: 100,
    productName: "농심 프레첼",
    brandName: "농심",
    criteriaWeight: 30,
    kcal: 125,
    sodium: 130,
    carbohydrate: 14,
    sugar: 8,
    fat: 7,
    transfat: 0,
    saturatedFat: 2.2,
    cholesterol: 2.1,
    protein: 1,
    cluster: 3,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 101,
    productName: "참크레커",
    brandName: "크라운",
    criteriaWeight: 37,
    kcal: 170,
    sodium: 240,
    carbohydrate: 27,
    sugar: 1,
    fat: 5,
    transfat: 0,
    saturatedFat: 2.8,
    cholesterol: 5,
    protein: 4,
    cluster: 2,
    spicy: 0,
    sweety: 0,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 102,
    productName: "제크",
    brandName: "롯데",
    criteriaWeight: 50,
    kcal: 250,
    sodium: 400,
    carbohydrate: 33,
    sugar: 4,
    fat: 12,
    transfat: 0.5,
    saturatedFat: 7,
    cholesterol: 0,
    protein: 3,
    cluster: 7,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 103,
    productName: "벌집 핏자",
    brandName: "농심",
    criteriaWeight: 30,
    kcal: 155,
    sodium: 190,
    carbohydrate: 20,
    sugar: 2.1,
    fat: 8,
    transfat: 0.5,
    saturatedFat: 2.6,
    cholesterol: 0,
    protein: 1,
    cluster: 0,
    spicy: 0,
    sweety: 1,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 104,
    productName: "깜뜨",
    brandName: "롯데",
    criteriaWeight: 50,
    kcal: 255,
    sodium: 160,
    carbohydrate: 34,
    sugar: 16,
    fat: 12,
    transfat: 0,
    saturatedFat: 7,
    cholesterol: 5,
    protein: 3,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 105,
    productName: "베이키",
    brandName: "크라운",
    criteriaWeight: 34,
    kcal: 165,
    sodium: 95,
    carbohydrate: 17,
    sugar: 10,
    fat: 10,
    transfat: 0,
    saturatedFat: 5,
    cholesterol: 40,
    protein: 2,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 106,
    productName: "콘초",
    brandName: "크라운",
    criteriaWeight: 66,
    kcal: 330,
    sodium: 55,
    carbohydrate: 45,
    sugar: 19,
    fat: 15,
    transfat: 0,
    saturatedFat: 6,
    cholesterol: 0,
    protein: 4,
    cluster: 5,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 107,
    productName: "고소미",
    brandName: "오리온",
    criteriaWeight: 40,
    kcal: 220,
    sodium: 120,
    carbohydrate: 25,
    sugar: 10,
    fat: 12,
    transfat: 0,
    saturatedFat: 5,
    cholesterol: 5,
    protein: 3,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 108,
    productName: "뽀또 치즈타르트맛",
    brandName: "크라운",
    criteriaWeight: 23,
    kcal: 115,
    sodium: 120,
    carbohydrate: 15,
    sugar: 5,
    fat: 5,
    transfat: 0,
    saturatedFat: 3.3,
    cholesterol: 5,
    protein: 2,
    cluster: 8,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 1,
  },
  {
    productID: 109,
    productName: "구운양파",
    brandName: "해태",
    criteriaWeight: 70,
    kcal: 425,
    sodium: 440,
    carbohydrate: 32,
    sugar: 4,
    fat: 31,
    transfat: 0,
    saturatedFat: 8,
    cholesterol: 0,
    protein: 31,
    cluster: 0,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 110,
    productName: "빈츠",
    brandName: "롯데",
    criteriaWeight: 8.5,
    kcal: 45,
    sodium: 15,
    carbohydrate: 5,
    sugar: 3,
    fat: 2.3,
    transfat: 0.2,
    saturatedFat: 1.4,
    cholesterol: 2,
    protein: 1,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 111,
    productName: "뻥이요",
    brandName: "서울식품",
    criteriaWeight: 100,
    kcal: 460,
    sodium: 90,
    carbohydrate: 83,
    sugar: 23,
    fat: 12,
    transfat: 0.5,
    saturatedFat: 5,
    cholesterol: 5,
    protein: 5,
    cluster: 5,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 112,
    productName: "그랑쉘 사과",
    brandName: "청우",
    criteriaWeight: 39,
    kcal: 162,
    sodium: 47,
    carbohydrate: 25,
    sugar: 11,
    fat: 6,
    transfat: 0,
    saturatedFat: 4,
    cholesterol: 5,
    protein: 2,
    cluster: 5,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 1,
  },
  {
    productID: 113,
    productName: "아그칩",
    brandName: "롯데",
    criteriaWeight: 100,
    kcal: 480,
    sodium: 530,
    carbohydrate: 73,
    sugar: 5,
    fat: 18,
    transfat: 0,
    saturatedFat: 8,
    cholesterol: 0,
    protein: 7,
    cluster: 8,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 114,
    productName: "뿌셔뿌셔 바베큐맛",
    brandName: "오뚜기",
    criteriaWeight: 90,
    kcal: 415,
    sodium: 950,
    carbohydrate: 61,
    sugar: 4,
    fat: 15,
    transfat: 0,
    saturatedFat: 6,
    cholesterol: 0,
    protein: 9,
    cluster: 6,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 115,
    productName: "후렌치파이 사과",
    brandName: "해태",
    criteriaWeight: 13,
    kcal: 60,
    sodium: 25,
    carbohydrate: 9,
    sugar: 4,
    fat: 2.3,
    transfat: 0,
    saturatedFat: 1.4,
    cholesterol: 0.6,
    protein: 1,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 1,
  },
  {
    productID: 116,
    productName: "구운감자",
    brandName: "해태",
    criteriaWeight: 20,
    kcal: 95,
    sodium: 120,
    carbohydrate: 14,
    sugar: 3,
    fat: 3.9,
    transfat: 0,
    saturatedFat: 2.1,
    cholesterol: 0.64,
    protein: 1,
    cluster: 0,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 117,
    productName: "빼빼로 오리지널",
    brandName: "롯데",
    criteriaWeight: 46,
    kcal: 225,
    sodium: 125,
    carbohydrate: 31,
    sugar: 12,
    fat: 9,
    transfat: 0,
    saturatedFat: 5,
    cholesterol: 5,
    protein: 4,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 118,
    productName: "크라운산도 딸기크림치즈",
    brandName: "크라운",
    criteriaWeight: 20,
    kcal: 100,
    sodium: 50,
    carbohydrate: 14,
    sugar: 6,
    fat: 4.6,
    transfat: 0,
    saturatedFat: 2.9,
    cholesterol: 5,
    protein: 1,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 1,
  },
  {
    productID: 119,
    productName: "오징어집",
    brandName: "농심",
    criteriaWeight: 30,
    kcal: 160,
    sodium: 150,
    carbohydrate: 18,
    sugar: 2,
    fat: 9,
    transfat: 0.5,
    saturatedFat: 2.9,
    cholesterol: 5,
    protein: 2,
    cluster: 8,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 120,
    productName: "초코틴틴",
    brandName: "해태",
    criteriaWeight: 15,
    kcal: 80,
    sodium: 20,
    carbohydrate: 9,
    sugar: 5,
    fat: 4.6,
    transfat: 0,
    saturatedFat: 2.6,
    cholesterol: 5,
    protein: 1,
    cluster: 5,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 121,
    productName: "빅파이 딸기",
    brandName: "크라운",
    criteriaWeight: 100,
    kcal: 470,
    sodium: 240,
    carbohydrate: 62,
    sugar: 35,
    fat: 22,
    transfat: 0,
    saturatedFat: 16,
    cholesterol: 5,
    protein: 6,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 1,
  },
  {
    productID: 122,
    productName: "쫀득쫀득 참붕어빵",
    brandName: "오리온",
    criteriaWeight: 58,
    kcal: 247,
    sodium: 140,
    carbohydrate: 34,
    sugar: 23,
    fat: 11,
    transfat: 0,
    saturatedFat: 5,
    cholesterol: 40,
    protein: 3,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 123,
    productName: "초코파이 인절미맛",
    brandName: "오리온",
    criteriaWeight: 28,
    kcal: 117,
    sodium: 90,
    carbohydrate: 18,
    sugar: 8,
    fat: 4.6,
    transfat: 0,
    saturatedFat: 2.8,
    cholesterol: 2,
    protein: 1,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 124,
    productName: "카라멜콘 메이플",
    brandName: "크라운",
    criteriaWeight: 59,
    kcal: 310,
    sodium: 170,
    carbohydrate: 40,
    sugar: 18,
    fat: 16,
    transfat: 0,
    saturatedFat: 6,
    cholesterol: 10,
    protein: 2,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 125,
    productName: "찰떡파이",
    brandName: "청우",
    criteriaWeight: 20,
    kcal: 85,
    sodium: 60,
    carbohydrate: 13,
    sugar: 6,
    fat: 3.3,
    transfat: 0,
    saturatedFat: 1.1,
    cholesterol: 0,
    protein: 1,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 126,
    productName: "찰떡쿠키",
    brandName: "청우",
    criteriaWeight: 107.5,
    kcal: 417,
    sodium: 325,
    carbohydrate: 80,
    sugar: 37,
    fat: 9,
    transfat: 0,
    saturatedFat: 4.9,
    cholesterol: 16,
    protein: 4,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 127,
    productName: "조청유과",
    brandName: "농심",
    criteriaWeight: 30,
    kcal: 165,
    sodium: 85,
    carbohydrate: 20,
    sugar: 8,
    fat: 9,
    transfat: 0.1,
    saturatedFat: 2.8,
    cholesterol: 0,
    protein: 0.7,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 128,
    productName: "추억의 건빵",
    brandName: "영양",
    criteriaWeight: 100,
    kcal: 423,
    sodium: 437,
    carbohydrate: 78,
    sugar: 20,
    fat: 8,
    transfat: 0,
    saturatedFat: 2.2,
    cholesterol: 7,
    protein: 10,
    cluster: 3,
    spicy: 0,
    sweety: 0,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 129,
    productName: "오사쯔",
    brandName: "해태",
    criteriaWeight: 30,
    kcal: 180,
    sodium: 120,
    carbohydrate: 17,
    sugar: 5,
    fat: 12,
    transfat: 0,
    saturatedFat: 4,
    cholesterol: 0,
    protein: 1,
    cluster: 3,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 130,
    productName: "콘치",
    brandName: "크라운",
    criteriaWeight: 30,
    kcal: 165,
    sodium: 60,
    carbohydrate: 2,
    sugar: 7,
    fat: 9,
    transfat: 0,
    saturatedFat: 5,
    cholesterol: 5,
    protein: 2,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 131,
    productName: "짱구는 못말려 초코비",
    brandName: "토하토",
    criteriaWeight: 100,
    kcal: 575,
    sodium: 245,
    carbohydrate: 60,
    sugar: 60,
    fat: 35,
    transfat: 0,
    saturatedFat: 20,
    cholesterol: 0,
    protein: 5,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 132,
    productName: "참ing 치즈크림",
    brandName: "크라운",
    criteriaWeight: 30,
    kcal: 150,
    sodium: 200,
    carbohydrate: 20,
    sugar: 7,
    fat: 7,
    transfat: 0,
    saturatedFat: 5,
    cholesterol: 0,
    protein: 2,
    cluster: 2,
    spicy: 0,
    sweety: 0,
    salty: 0,
    savory: 1,
    sour: 1,
  },
  {
    productID: 133,
    productName: "빼빼로 아몬드",
    brandName: "롯데",
    criteriaWeight: 37,
    kcal: 195,
    sodium: 85,
    carbohydrate: 21,
    sugar: 9,
    fat: 10,
    transfat: 0,
    saturatedFat: 4.7,
    cholesterol: 5,
    protein: 5,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 134,
    productName: "포카칩 어니언맛",
    brandName: "오리온",
    criteriaWeight: 30,
    kcal: 171,
    sodium: 95,
    carbohydrate: 16,
    sugar: 1,
    fat: 11,
    transfat: 0,
    saturatedFat: 4.1,
    cholesterol: 0,
    protein: 2,
    cluster: 4,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 135,
    productName: "참쌀 설병",
    brandName: "크라운",
    criteriaWeight: 26,
    kcal: 125,
    sodium: 85,
    carbohydrate: 20,
    sugar: 4,
    fat: 4.3,
    transfat: 0,
    saturatedFat: 1.7,
    cholesterol: 0,
    protein: 1,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 136,
    productName: "게메즈 에낙 라면과자",
    brandName: "다인인터내셔널",
    criteriaWeight: 30,
    kcal: 150,
    sodium: 210,
    carbohydrate: 19,
    sugar: 1,
    fat: 7,
    transfat: 0,
    saturatedFat: 2.8,
    cholesterol: 0,
    protein: 3,
    cluster: 2,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 137,
    productName: "포테이토 크리스프 스낵 사워크림맛",
    brandName: "윈윈푸드",
    criteriaWeight: 20,
    kcal: 98,
    sodium: 67,
    carbohydrate: 13.2,
    sugar: 3.1,
    fat: 4.8,
    transfat: 0,
    saturatedFat: 2.2,
    cholesterol: 0,
    protein: 1.6,
    cluster: 4,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 1,
  },
  {
    productID: 138,
    productName: "꼬북칩 인절미맛",
    brandName: "오리온",
    criteriaWeight: 30,
    kcal: 172,
    sodium: 110,
    carbohydrate: 14,
    sugar: 4,
    fat: 12,
    transfat: 0,
    saturatedFat: 4.9,
    cholesterol: 0,
    protein: 2,
    cluster: 3,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 139,
    productName: "뿌셔뿌셔 불고기맛",
    brandName: "오뚜기",
    criteriaWeight: 90,
    kcal: 385,
    sodium: 830,
    carbohydrate: 57,
    sugar: 4,
    fat: 14,
    transfat: 0,
    saturatedFat: 6,
    cholesterol: 0,
    protein: 8,
    cluster: 7,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 140,
    productName: "차카니",
    brandName: "PT.Tanara Jaya",
    criteriaWeight: 18,
    kcal: 67.3,
    sodium: 152.2,
    carbohydrate: 14.2,
    sugar: 2.1,
    fat: 0.7,
    transfat: 0,
    saturatedFat: 0.7,
    cholesterol: 0,
    protein: 1.4,
    cluster: 7,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 141,
    productName: "밭두렁",
    brandName: "영진식품",
    criteriaWeight: 45,
    kcal: 181,
    sodium: 168,
    carbohydrate: 40,
    sugar: 4,
    fat: 1.5,
    transfat: 0,
    saturatedFat: 0.5,
    cholesterol: 0,
    protein: 3,
    cluster: 3,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 142,
    productName: "초코 팅촉",
    brandName: "코스모스",
    criteriaWeight: 30,
    kcal: 160,
    sodium: 40,
    carbohydrate: 20,
    sugar: 5,
    fat: 8,
    transfat: 0,
    saturatedFat: 7.3,
    cholesterol: 0,
    protein: 2,
    cluster: 5,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 143,
    productName: "마켓오 리얼치즈칩",
    brandName: "오리온",
    criteriaWeight: 62,
    kcal: 306,
    sodium: 450,
    carbohydrate: 40,
    sugar: 6,
    fat: 14,
    transfat: 0.5,
    saturatedFat: 3.4,
    cholesterol: 11,
    protein: 5,
    cluster: 2,
    spicy: 0,
    sweety: 0,
    salty: 0,
    savory: 1,
    sour: 1,
  },
  {
    productID: 144,
    productName: "피자감자칩",
    brandName: "해태",
    criteriaWeight: 55,
    kcal: 315,
    sodium: 230,
    carbohydrate: 28,
    sugar: 2,
    fat: 21,
    transfat: 0,
    saturatedFat: 7,
    cholesterol: 0,
    protein: 3,
    cluster: 3,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 145,
    productName: "옥수수 브이콘",
    brandName: "진한제과",
    criteriaWeight: 50,
    kcal: 240,
    sodium: 187.8,
    carbohydrate: 35.7,
    sugar: 5.3,
    fat: 9.6,
    transfat: 0,
    saturatedFat: 2.4,
    cholesterol: 0,
    protein: 2.9,
    cluster: 3,
    spicy: 0,
    sweety: 1,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 146,
    productName: "매운 새우깡",
    brandName: "농심",
    criteriaWeight: 30,
    kcal: 150,
    sodium: 220,
    carbohydrate: 20,
    sugar: 2.1,
    fat: 7,
    transfat: 0,
    saturatedFat: 2,
    cholesterol: 0.9,
    protein: 2,
    cluster: 2,
    spicy: 1,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 147,
    productName: "고구마형 과자",
    brandName: "노브랜드",
    criteriaWeight: 100,
    kcal: 449,
    sodium: 460,
    carbohydrate: 73,
    sugar: 12,
    fat: 13,
    transfat: 0,
    saturatedFat: 6,
    cholesterol: 0,
    protein: 10,
    cluster: 8,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 148,
    productName: "도도한 나쵸 오리지널",
    brandName: "오리온",
    criteriaWeight: 74,
    kcal: 389,
    sodium: 510,
    carbohydrate: 45,
    sugar: 2,
    fat: 21,
    transfat: 0,
    saturatedFat: 5,
    cholesterol: 0,
    protein: 5,
    cluster: 2,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 149,
    productName: "소라형 과자",
    brandName: "코스모스",
    criteriaWeight: 62,
    kcal: 285,
    sodium: 198,
    carbohydrate: 44,
    sugar: 12,
    fat: 10,
    transfat: 0,
    saturatedFat: 4,
    cholesterol: 0,
    protein: 5,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 150,
    productName: "트위스트",
    brandName: "코스모스",
    criteriaWeight: 55,
    kcal: 290,
    sodium: 135,
    carbohydrate: 35,
    sugar: 9,
    fat: 15,
    transfat: 0,
    saturatedFat: 7,
    cholesterol: 0,
    protein: 4,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 151,
    productName: "포테토칩 오리지널",
    brandName: "농심",
    criteriaWeight: 30,
    kcal: 165,
    sodium: 130,
    carbohydrate: 16,
    sugar: 1,
    fat: 11,
    transfat: 0,
    saturatedFat: 3.7,
    cholesterol: 0,
    protein: 1,
    cluster: 3,
    spicy: 0,
    sweety: 0,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 152,
    productName: "오감자 딥바베큐",
    brandName: "오리온",
    criteriaWeight: 30,
    kcal: 135,
    sodium: 160,
    carbohydrate: 17,
    sugar: 4,
    fat: 7,
    transfat: 0.1,
    saturatedFat: 2.9,
    cholesterol: 0,
    protein: 1,
    cluster: 8,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 153,
    productName: "나나콘",
    brandName: "서울식품",
    criteriaWeight: 50,
    kcal: 245,
    sodium: 80,
    carbohydrate: 35,
    sugar: 7,
    fat: 11,
    transfat: 0,
    saturatedFat: 0.9,
    cholesterol: 0,
    protein: 2,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 154,
    productName: "꾀돌이",
    brandName: "광일제과",
    criteriaWeight: 45,
    kcal: 196,
    sodium: 119,
    carbohydrate: 34,
    sugar: 9,
    fat: 5,
    transfat: 0,
    saturatedFat: 2,
    cholesterol: 0,
    protein: 5,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 155,
    productName: "꼬깔콘 매콤달콤한맛",
    brandName: "롯데",
    criteriaWeight: 30,
    kcal: 175,
    sodium: 200,
    carbohydrate: 18,
    sugar: 2,
    fat: 11,
    transfat: 0,
    saturatedFat: 2.9,
    cholesterol: 0,
    protein: 2,
    cluster: 2,
    spicy: 1,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 156,
    productName: "곡물그대로 21 크리스피롤",
    brandName: "개미식품",
    criteriaWeight: 100,
    kcal: 560,
    sodium: 220,
    carbohydrate: 54,
    sugar: 5,
    fat: 36,
    transfat: 0,
    saturatedFat: 14,
    cholesterol: 5,
    protein: 5,
    cluster: 1,
    spicy: 0,
    sweety: 0,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 157,
    productName: "누드빼빼로",
    brandName: "롯데",
    criteriaWeight: 43,
    kcal: 235,
    sodium: 90,
    carbohydrate: 27,
    sugar: 15,
    fat: 13,
    transfat: 0,
    saturatedFat: 8,
    cholesterol: 0,
    protein: 2,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 158,
    productName: "몰티저스",
    brandName: "마즈",
    criteriaWeight: 37,
    kcal: 185,
    sodium: 140,
    carbohydrate: 23,
    sugar: 20,
    fat: 9,
    transfat: 0,
    saturatedFat: 5.6,
    cholesterol: 0,
    protein: 3,
    cluster: 3,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 159,
    productName: "쫀득 초코칩",
    brandName: "청우",
    criteriaWeight: 90,
    kcal: 360,
    sodium: 230,
    carbohydrate: 62,
    sugar: 33,
    fat: 11,
    transfat: 0,
    saturatedFat: 3,
    cholesterol: 17,
    protein: 3,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 160,
    productName: "듀팝스 팝콘",
    brandName: "크라운",
    criteriaWeight: 49,
    kcal: 250,
    sodium: 220,
    carbohydrate: 29,
    sugar: 2,
    fat: 14,
    transfat: 0,
    saturatedFat: 6,
    cholesterol: 5,
    protein: 4,
    cluster: 3,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 161,
    productName: "참ing 치즈레몬향",
    brandName: "크라운",
    criteriaWeight: 100,
    kcal: 495,
    sodium: 600,
    carbohydrate: 67,
    sugar: 29,
    fat: 22,
    transfat: 0,
    saturatedFat: 17,
    cholesterol: 5,
    protein: 7,
    cluster: 0,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 1,
  },
  {
    productID: 162,
    productName: "프레첼 체다치즈맛",
    brandName: "파란푸드",
    criteriaWeight: 30,
    kcal: 130,
    sodium: 210,
    carbohydrate: 22,
    sugar: 1,
    fat: 3,
    transfat: 1.5,
    saturatedFat: 0,
    cholesterol: 0,
    protein: 3,
    cluster: 2,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 163,
    productName: "로아커 웨하스 바닐라",
    brandName: "로아커",
    criteriaWeight: 45,
    kcal: 234,
    sodium: 71,
    carbohydrate: 27.5,
    sugar: 13.7,
    fat: 12.2,
    transfat: 0,
    saturatedFat: 10.5,
    cholesterol: 0,
    protein: 3.5,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 164,
    productName: "로아커 웨하스 녹차",
    brandName: "로아커",
    criteriaWeight: 37.5,
    kcal: 189,
    sodium: 63,
    carbohydrate: 23.1,
    sugar: 10.1,
    fat: 9.3,
    transfat: 0,
    saturatedFat: 7.9,
    cholesterol: 0,
    protein: 3.1,
    cluster: 1,
    spicy: 0,
    sweety: 0,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 165,
    productName: "예감 치즈그라탕",
    brandName: "오리온",
    criteriaWeight: 46,
    kcal: 227,
    sodium: 280,
    carbohydrate: 30,
    sugar: 4,
    fat: 11,
    transfat: 0.5,
    saturatedFat: 5,
    cholesterol: 0,
    protein: 2,
    cluster: 0,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 166,
    productName: "칙촉 티라미수맛",
    brandName: "롯데",
    criteriaWeight: 180,
    kcal: 870,
    sodium: 580,
    carbohydrate: 97,
    sugar: 53,
    fat: 48,
    transfat: 0.9,
    saturatedFat: 28,
    cholesterol: 25,
    protein: 12,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 167,
    productName: "맛동산 흑당쇼콜라",
    brandName: "해태",
    criteriaWeight: 30,
    kcal: 150,
    sodium: 15,
    carbohydrate: 20,
    sugar: 9,
    fat: 7,
    transfat: 0,
    saturatedFat: 1.2,
    cholesterol: 0,
    protein: 2,
    cluster: 5,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 168,
    productName: "치즈볼",
    brandName: "노브랜드",
    criteriaWeight: 100,
    kcal: 520,
    sodium: 1140,
    carbohydrate: 63,
    sugar: 9,
    fat: 27,
    transfat: 0,
    saturatedFat: 11,
    cholesterol: 5,
    protein: 5,
    cluster: 6,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 169,
    productName: "다쿠아즈",
    brandName: "오리온",
    criteriaWeight: 22,
    kcal: 95,
    sodium: 40,
    carbohydrate: 12,
    sugar: 11,
    fat: 4.3,
    transfat: 0,
    saturatedFat: 1.3,
    cholesterol: 0.5,
    protein: 2,
    cluster: 1,
    spicy: 0,
    sweety: 0,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 170,
    productName: "오뜨 쇼콜라 케익",
    brandName: "오리온",
    criteriaWeight: 25,
    kcal: 123,
    sodium: 55,
    carbohydrate: 13,
    sugar: 8,
    fat: 7,
    transfat: 0.07,
    saturatedFat: 4.3,
    cholesterol: 21,
    protein: 2,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 171,
    productName: "생미쉘 마들렌",
    brandName: "생미쉘 비스킷",
    criteriaWeight: 62,
    kcal: 267,
    sodium: 453,
    carbohydrate: 3.4,
    sugar: 18.6,
    fat: 13,
    transfat: 0,
    saturatedFat: 2,
    cholesterol: 0,
    protein: 3.4,
    cluster: 2,
    spicy: 0,
    sweety: 0,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 172,
    productName: "롱스",
    brandName: "크라운",
    criteriaWeight: 22,
    kcal: 100,
    sodium: 30,
    carbohydrate: 13,
    sugar: 9,
    fat: 4.7,
    transfat: 0,
    saturatedFat: 2.4,
    cholesterol: 15,
    protein: 1,
    cluster: 5,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 173,
    productName: "미쯔",
    brandName: "오리온",
    criteriaWeight: 42,
    kcal: 208,
    sodium: 120,
    carbohydrate: 31,
    sugar: 14,
    fat: 8,
    transfat: 0,
    saturatedFat: 4,
    cholesterol: 0,
    protein: 3,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 174,
    productName: "고속도로 뻥튀기",
    brandName: "공주뻥과자",
    criteriaWeight: 100,
    kcal: 373.21,
    sodium: 10,
    carbohydrate: 89.8,
    sugar: 2.2,
    fat: 0.4,
    transfat: 0,
    saturatedFat: 0,
    cholesterol: 0,
    protein: 2.6,
    cluster: 5,
    spicy: 0,
    sweety: 0,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 175,
    productName: "노래방 마카로니",
    brandName: "우리식품",
    criteriaWeight: 100,
    kcal: 493,
    sodium: 581,
    carbohydrate: 73,
    sugar: 0,
    fat: 12,
    transfat: 0,
    saturatedFat: 5,
    cholesterol: 3,
    protein: 10,
    cluster: 0,
    spicy: 0,
    sweety: 0,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 176,
    productName: "초코송이",
    brandName: "오리온",
    criteriaWeight: 50,
    kcal: 265,
    sodium: 110,
    carbohydrate: 31,
    sugar: 16,
    fat: 14,
    transfat: 0,
    saturatedFat: 8,
    cholesterol: 5,
    protein: 3,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 177,
    productName: "배배",
    brandName: "오리온",
    criteriaWeight: 40,
    kcal: 192,
    sodium: 160,
    carbohydrate: 28,
    sugar: 13,
    fat: 8,
    transfat: 0,
    saturatedFat: 4.9,
    cholesterol: 10,
    protein: 2,
    cluster: 3,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 178,
    productName: "썬칩 갈릭바게트맛",
    brandName: "오리온",
    criteriaWeight: 30,
    kcal: 151,
    sodium: 130,
    carbohydrate: 20,
    sugar: 4,
    fat: 7,
    transfat: 0,
    saturatedFat: 3,
    cholesterol: 0,
    protein: 2,
    cluster: 3,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 179,
    productName: "도도한 나쵸 사워크림&어니언",
    brandName: "오리온",
    criteriaWeight: 30,
    kcal: 156,
    sodium: 170,
    carbohydrate: 19,
    sugar: 1,
    fat: 8,
    transfat: 0,
    saturatedFat: 3.4,
    cholesterol: 0,
    protein: 2,
    cluster: 0,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 180,
    productName: "쿠쉬쿠쉬 크루아상",
    brandName: "오리온",
    criteriaWeight: 16.4,
    kcal: 75,
    sodium: 90,
    carbohydrate: 12,
    sugar: 2,
    fat: 2.5,
    transfat: 0,
    saturatedFat: 1.5,
    cholesterol: 0,
    protein: 1,
    cluster: 8,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 181,
    productName: "고소한인절미",
    brandName: "유어스",
    criteriaWeight: 56,
    kcal: 335,
    sodium: 50,
    carbohydrate: 31,
    sugar: 9,
    fat: 22,
    transfat: 0,
    saturatedFat: 4.4,
    cholesterol: 0,
    protein: 3,
    cluster: 5,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 182,
    productName: "초코써니볼",
    brandName: "유어스",
    criteriaWeight: 30,
    kcal: 210,
    sodium: 139,
    carbohydrate: 37,
    sugar: 16,
    fat: 6,
    transfat: 0,
    saturatedFat: 1,
    cholesterol: 2,
    protein: 2,
    cluster: 8,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 183,
    productName: "버터갈릭팝콘",
    brandName: "유어스",
    criteriaWeight: 60,
    kcal: 285,
    sodium: 340,
    carbohydrate: 40,
    sugar: 4,
    fat: 12,
    transfat: 0,
    saturatedFat: 1.8,
    cholesterol: 0,
    protein: 5,
    cluster: 0,
    spicy: 0,
    sweety: 1,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 184,
    productName: "초코렛타",
    brandName: "유어스",
    criteriaWeight: 76,
    kcal: 425,
    sodium: 100,
    carbohydrate: 45,
    sugar: 22,
    fat: 26,
    transfat: 0,
    saturatedFat: 23,
    cholesterol: 0,
    protein: 3,
    cluster: 5,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 185,
    productName: "꼬불이 라면스낵",
    brandName: "씨유",
    criteriaWeight: 50,
    kcal: 300,
    sodium: 435,
    carbohydrate: 27,
    sugar: 2,
    fat: 21,
    transfat: 0,
    saturatedFat: 0,
    cholesterol: 0,
    protein: 0.4,
    cluster: 7,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 186,
    productName: "초코바나나콘",
    brandName: "씨유",
    criteriaWeight: 65,
    kcal: 370,
    sodium: 75,
    carbohydrate: 36,
    sugar: 14,
    fat: 24,
    transfat: 0,
    saturatedFat: 18,
    cholesterol: 4,
    protein: 3,
    cluster: 5,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 187,
    productName: "마늘바게트",
    brandName: "유어스",
    criteriaWeight: 108,
    kcal: 497,
    sodium: 532,
    carbohydrate: 73,
    sugar: 16,
    fat: 17,
    transfat: 0,
    saturatedFat: 7,
    cholesterol: 0,
    protein: 13,
    cluster: 8,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 188,
    productName: "후렌치파이 초코스프레드",
    brandName: "해태",
    criteriaWeight: 12,
    kcal: 60,
    sodium: 35,
    carbohydrate: 7,
    sugar: 2,
    fat: 3.4,
    transfat: 0,
    saturatedFat: 1.7,
    cholesterol: 1,
    protein: 1,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 189,
    productName: "허니버터 아몬드",
    brandName: "길림양행",
    criteriaWeight: 30,
    kcal: 155,
    sodium: 700,
    carbohydrate: 13,
    sugar: 10,
    fat: 8,
    transfat: 0,
    saturatedFat: 0,
    cholesterol: 0,
    protein: 5,
    cluster: "x",
    spicy: 0,
    sweety: 1,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 190,
    productName: "스윙칩 간장치킨맛",
    brandName: "오리온",
    criteriaWeight: 60,
    kcal: 343,
    sodium: 320,
    carbohydrate: 31,
    sugar: 2,
    fat: 23,
    transfat: 0,
    saturatedFat: 7,
    cholesterol: 0,
    protein: 3,
    cluster: 8,
    spicy: 0,
    sweety: 1,
    salty: 1,
    savory: 0,
    sour: 0,
  },
  {
    productID: 191,
    productName: "통크",
    brandName: "오리온",
    criteriaWeight: 22,
    kcal: 109,
    sodium: 50,
    carbohydrate: 14,
    sugar: 7,
    fat: 5,
    transfat: 0,
    saturatedFat: 3.3,
    cholesterol: 0,
    protein: 2,
    cluster: 1,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 192,
    productName: "콘딸바",
    brandName: "크라운",
    criteriaWeight: 66,
    kcal: 346,
    sodium: 40,
    carbohydrate: 43,
    sugar: 18,
    fat: 18,
    transfat: 0,
    saturatedFat: 11,
    cholesterol: 5,
    protein: 3,
    cluster: 5,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 193,
    productName: "오징어다리 달달구이맛",
    brandName: "농심",
    criteriaWeight: 30,
    kcal: 140,
    sodium: 200,
    carbohydrate: 22,
    sugar: 7,
    fat: 5,
    transfat: 0,
    saturatedFat: 1.7,
    cholesterol: 5,
    protein: 1.6,
    cluster: 2,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 0,
    sour: 0,
  },
  {
    productID: 194,
    productName: "파래전병",
    brandName: "왕궁병과",
    criteriaWeight: 28,
    kcal: 110,
    sodium: 10,
    carbohydrate: 25,
    sugar: 13,
    fat: 0.2,
    transfat: 0,
    saturatedFat: 0.1,
    cholesterol: 2,
    protein: 2,
    cluster: 5,
    spicy: 0,
    sweety: 0,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 195,
    productName: "키드오 버터크림맛",
    brandName: "키드오",
    criteriaWeight: 15,
    kcal: 75,
    sodium: 110,
    carbohydrate: 10,
    sugar: 3,
    fat: 3.5,
    transfat: 0,
    saturatedFat: 2,
    cholesterol: 0,
    protein: 1,
    cluster: 2,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
  {
    productID: 196,
    productName: "키드오 버터레몬맛",
    brandName: "키드오",
    criteriaWeight: 15,
    kcal: 75,
    sodium: 70,
    carbohydrate: 10.5,
    sugar: 3.5,
    fat: 3,
    transfat: 0,
    saturatedFat: 1.75,
    cholesterol: 0,
    protein: 1,
    cluster: 8,
    spicy: 0,
    sweety: 0,
    salty: 0,
    savory: 1,
    sour: 1,
  },
  {
    productID: 197,
    productName: "베이비스타 치킨맛라멘",
    brandName: "오야츠",
    criteriaWeight: 90,
    kcal: 435,
    sodium: 1000,
    carbohydrate: 58,
    sugar: 2,
    fat: 19,
    transfat: 0,
    saturatedFat: 9,
    cholesterol: 0,
    protein: 8,
    cluster: 6,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 198,
    productName: "꼬깔콘 군옥수수맛",
    brandName: "롯데",
    criteriaWeight: 30,
    kcal: 180,
    sodium: 130,
    carbohydrate: 18,
    sugar: 2,
    fat: 11,
    transfat: 0,
    saturatedFat: 3.5,
    cholesterol: 0,
    protein: 2,
    cluster: 3,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 199,
    productName: "쫄병스낵 짜파게티맛",
    brandName: "농심",
    criteriaWeight: 30,
    kcal: 150,
    sodium: 250,
    carbohydrate: 18,
    sugar: 2.7,
    fat: 8,
    transfat: 0,
    saturatedFat: 3.7,
    cholesterol: 0,
    protein: 2,
    cluster: 7,
    spicy: 0,
    sweety: 0,
    salty: 1,
    savory: 1,
    sour: 0,
  },
  {
    productID: 200,
    productName: "씨유 오란다",
    brandName: "씨유",
    criteriaWeight: 120,
    kcal: 575,
    sodium: 320,
    carbohydrate: 86,
    sugar: 35,
    fat: 21,
    transfat: 0,
    saturatedFat: 9,
    cholesterol: 0,
    protein: 11,
    cluster: 4,
    spicy: 0,
    sweety: 1,
    salty: 0,
    savory: 1,
    sour: 0,
  },
]

export default productList
