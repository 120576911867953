const URL_DEV = {
  HOME_URL: "http://localhost:3000",
  API_URL: "http://localhost:6495",
  AUTH_URL: "http://localhost:6495",
}

// const URL_DEV = {
//   HOME_URL: "http://192.168.0.9:3000",
//   API_URL: "http://192.168.0.9:6495",
//   AUTH_URL: "http://192.168.0.9:6495",
// }

const URL_PROD = {
  HOME_URL: "https://dev.snackpot.kr",
  API_URL: "https://api.snackpot.kr",
  AUTH_URL: "https://api.snackpot.kr",
}

const FINAL_URL = process.env.REACT_APP_ENV === "prod" ? URL_PROD : URL_DEV

export default FINAL_URL
