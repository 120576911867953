import React from "react"

import classNames from "classnames/bind"
import styles from "../../styles/Detail_n.module.scss"

const TagItem = ({ tagName }) => {
  return <div className={cn("product_tag")}>{tagName}</div>
}

const cn = classNames.bind(styles)

export default TagItem
