import createReducer from "../common"
import api from "../../api/index"
import TUTORIAL_INITIAL_STATE from "./state"

const id = "tutorial"

/* Action Constants */
const SET_NAME = `${id}/SET_NAME`
const SET_GENDER = `${id}/SET_GENDER`
const SET_AGESPAN = `${id}/SET_AGESPAN`
const TOGGLE_PRODUCT_SELECTION = `${id}/TOGGLE_PRODUCT_SELECTION`
const SET_PRODUCT_RATING = `${id}/SET_PRODUCT_RATING`
const SET_FINISHED = `${id}/SET_FINISHED`
const SET_ENTRANCE_TIME = `${id}/SET_ENTRANCE_TIME`
const SET_SELECTED_COUNT = `${id}/SET_SELECTED_COUNT`
const INCREASE_SELECTED_COUNT = `${id}/INCREASE_SELECTED_COUNT`
const INCREASE_CANCELED_COUNT = `${id}/INCREASE_CANCELED_COUNT`

/* Action Creators */
export const setName = name => ({ type: SET_NAME, name })
export const setGender = gender => ({ type: SET_GENDER, gender })
export const setAgeSpan = ageSpan => ({ type: SET_AGESPAN, ageSpan })
export const toggleProductSelection = productID => ({ type: TOGGLE_PRODUCT_SELECTION, productID })
export const setProductRating = (productID, rating) => ({ type: SET_PRODUCT_RATING, productID, rating })
export const setFinished = () => ({ type: SET_FINISHED })
export const setEntranceTime = () => ({ type: SET_ENTRANCE_TIME })
export const setSelectedCount = cnt => ({ type: SET_SELECTED_COUNT, cnt })
export const increaseSelectedCount = () => ({ type: INCREASE_SELECTED_COUNT })
export const increaseCanceledCount = () => ({ type: INCREASE_CANCELED_COUNT })


/* Reducer */
const reducer = createReducer(TUTORIAL_INITIAL_STATE, {
  [SET_NAME]: (state, action) => (state.name = action.name),
  [SET_GENDER]: (state, action) => (state.gender = action.gender),
  [SET_AGESPAN]: (state, action) => (state.ageSpan = action.ageSpan),
  [TOGGLE_PRODUCT_SELECTION]: (state, action) =>
    (state.productSelection[action.productID - 1] = !state.productSelection[action.productID - 1]),
  [SET_PRODUCT_RATING]: (state, action) => (state.productSelection[action.productID - 1] = action.rating),
  [SET_FINISHED]: state => (state.finished = true),
  [SET_SELECTED_COUNT]: (state, action) => (state.selectedCount = action.cnt),
  [INCREASE_SELECTED_COUNT]: state => state.selectedCount++,
  [INCREASE_CANCELED_COUNT]: state => state.canceledCount++,
  [SET_ENTRANCE_TIME]: state => (state.entranceTime = new Date()),
})

export default reducer
