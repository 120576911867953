import React from "react"

import classNames from "classnames/bind"
import styles from "../../styles/Detail_n.module.scss"

const DiscountTagItem = ({ tagName }) => {
  return (
    <div className={cn("discount_tag")}>
      <span>{tagName}</span>
    </div>
  )
}

const cn = classNames.bind(styles)

export default DiscountTagItem
