import React from "react"

import classNames from "classnames/bind"
import styles from "../../../../styles/NewComment.module.scss"
import MESSAGE from "../../../../constants/message"
import api from "../../../../api/index"
import { connect } from "react-redux"

import ProductInfoPreview from "./ProductInfoPreview"
import RatingStars from "./RatingStars"
import DeviceMargin from "../../../common/DeviceMargin"

import close_btn from "../../../../assets/close.svg"

class NewComment extends React.Component {
  state = {
    rating: 5.0,
    commentText: "",
  }

  goBack = () => {
    this.props.history.goBack()
  }

  handleRatingChange = rating => {
    this.setState({ rating })
  }

  handleCommentTextChange = e => {
    this.setState({ commentText: e.target.value })
  }

  uploadComment = () => {
    const { productID } = this.props.match.params
    const { rating, commentText } = this.state
    // const { userID, displayName, provider } = this.props.user

    Promise.all([api.newComment(productID, { commentText, rating }), api.setProductRating(productID, { rating })])
      .then(() => {
        alert(MESSAGE.NEW_COMMENT_WRITE_SUCCESS)
        this.goBack()
      })
      .catch(err => {
        alert(MESSAGE.NEW_COMMNET_FAIL_TO_WRITE)
      })
  }

  render() {
    const { productID } = this.props.match.params

    return (
      <div className={cn("wrapper")}>
        <DeviceMargin height="50" />

        <div className={cn("page_title")}>
          <span className={cn("title_text")}>리뷰 작성하기</span>
          <div className={cn("close_btn")} onClick={this.goBack}>
            <img src={close_btn} alt="닫기" />
          </div>
        </div>

        <ProductInfoPreview productID={productID} />

        <RatingStars ref={this.ratingSliderRef} setRating={this.handleRatingChange} />

        <div className={cn("input_wrapper")}>
          <textarea className={cn("preview_text")} placeholder={MESSAGE.NEW_COMMENT_PLACEHOLDER} onChange={this.handleCommentTextChange}></textarea>
        </div>

        <div className={cn("btn_section")}>
          <div className={cn("btn")} onClick={this.uploadComment}>
            <span>등록하기</span>
          </div>
        </div>
      </div>
    )
  }
}

const cn = classNames.bind(styles)

const mapStateToProps = state => ({
  authenticated: state.auth.authenticated,
  user: state.auth.user,
})

export default connect(mapStateToProps)(NewComment)
