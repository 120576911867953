import React from "react"

import classNames from "classnames/bind"
import styles from "../../../styles/Main.module.scss"
import { Link } from "react-router-dom"

class RankingIndividualItem extends React.Component {
  state = {}

  render() {
    const { rank } = this.props
    const { id, name_kor, image_front_250, brandname } = this.props.productInfo

    return (
      <Link to={"/detail/" + id}>
        <li className={cn("ranking_each_item")}>
          <div className={cn("ranking_item_image")}>
            <img src={image_front_250} alt="" />
          </div>
          <div className={cn("ranking_number")}>
            <span>{rank}</span>
          </div>
          <div className={cn("ranking_item_info")}>
            <div className={cn("ranking_item_name")}>{name_kor}</div>
            <div className={cn("ranking_item_brandname")}>{brandname}</div>
          </div>
        </li>
      </Link>
    )
  }
}

const cn = classNames.bind(styles)

export default RankingIndividualItem
