import React from "react"

class Videos extends React.Component {
  state = {}
  render() {
    return (
      <>
        <div
          style={{
            overflow: "hidden",
            WebkitMaskImage: "-webkit-radial-gradient(white, black)",
            borderRadius: 30,
            width: "calc(100% - 20px)",
            maxWidth: "400px",
            height: "250px",

            border: "10px solid rgba(255,255,255,1)",
            margin: "10px 10px",
            boxSizing: "border-box",
          }}
        >
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/9lbPKc70O14?controls=0?autoplay=1&mute=1&playsinline=1&rel=0"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
            style={{ width: "100%", height: "100%" }}
            allowFullScreen={false}
            title="TEST"
          ></iframe>
        </div>
      </>
    )
  }
}

export default Videos
