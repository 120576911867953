import React from "react"

import styles from "../../../styles/Detail.module.scss"
import { connect } from "react-redux"
import { getComments, increaseCommentLikeRequest, commentLikeRequest } from "../../../store/detail"

import RatingStarImages from "../../common/RatingStarImages"

import api from "../../../api/index"
import { getDateFromTimestamp } from "../../../util/index"

import sns_basic from "../../../assets/comments/sns_basic.svg"
import sns_kakao from "../../../assets/comments/sns_kakao.png"
import sns_naver from "../../../assets/comments/sns_naver.png"
import sns_facebook from "../../../assets/comments/sns_facebook.png"

class Comment extends React.Component {
  state = {
    isMyComment: this.props.commentInfo.userID === this.props.userID,
  }

  handleCommentLike = () => {
    const { commentID } = this.props.commentInfo

    this.props.dispatch(commentLikeRequest(commentID))
    // this.props.dispatch(increaseCommentLikeRequest(commentID))
  }

  render() {
    const { commentText, writtenDate, provider, displayName, likeCount, rating } = this.props.commentInfo

    return (
      <div className={styles.comment + " " + (this.state.isMyComment && styles.my_comment)}>
        <div className={styles.comment_basic_info}>
          <img src={provider ? snsImages[provider] : snsImages.basic} alt="" />
          <div className={styles.user_name}>{this.state.isMyComment ? "[내가 남긴 리뷰]" : displayName}</div>
          <div className={styles.date}>{getDateFromTimestamp(writtenDate)}</div>
        </div>

        <div className={styles.user_stars}>
          <RatingStarImages rating={rating} />
        </div>

        <div className={styles.comment_content}>{commentText}</div>
        <div className={styles.comment_like} onClick={this.handleCommentLike}>
          <div className={styles.thumbs}>
            <img src="/images/thumbs-up@3x.png" alt="star" />
          </div>
          <span className={styles.number_of_likes}>{likeCount}</span>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userID: state.auth.userID,
  productID: state.detail.productID,
})

const snsImages = {
  kakao: sns_kakao,
  naver: sns_naver,
  facebook: sns_facebook,
  basic: sns_basic,
}

export default connect(mapStateToProps)(Comment)
