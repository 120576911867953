import React from "react"

import classNames from "classnames/bind"
import styles from "../../../styles/Modal.module.scss"

import ScrollLock from "react-scrolllock"
import { useSelector, useDispatch } from "react-redux"
import ModalMap from "./ModalMap"
import { setShowModal } from "../../../store/common/index"

const ModalWrapper = props => {
  const { showModal, ModalComponent } = useSelector(state => ({
    showModal: state.common.modal.showModal,
    ModalComponent: state.common.modal.modalComponent,
  }))

  const dispatch = useDispatch()

  /* Closing the modal only if the background is touched */
  const closeModalHandler = e => {
    const modalElement = document.querySelector("#modalBg > div").getBoundingClientRect()
    const { top, height, left, width } = modalElement

    const ifTouchedWithinModal =
      top <= e.clientY && e.clientY <= top + height && left <= e.clientX && e.clientX <= left + width

    if (!ifTouchedWithinModal) dispatch(setShowModal(false))
  }

  return (
    showModal && (
      <ScrollLock>
        <div id="modalBg" className={cn("modal-bg")} onClick={closeModalHandler}>
          {ModalMap[ModalComponent]}
        </div>
      </ScrollLock>
    )
  )
}

const cn = classNames.bind(styles)

export default ModalWrapper
