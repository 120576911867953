import React from "react"

import { Provider } from "react-redux"
import store from "../store/index"
import { BrowserRouter as Router, Route } from "react-router-dom"

import Navigation from "../components/common/Navigation"
import Login from "../components/login/Login"
import Detail from "../components/detail/Detail"
import Search from "../components/search/Search"
import SearchResult from "../components/search/SearchResult/SearchResult"
import ModalWrapper from "../components/common/modal/ModalWrapper"
import NewComment from "../components/detail/UserComments/NewComment/NewComment"
import Ranking from "../components/ranking/Ranking"
import Home from "../components/home/Home"
import SearchByKeyword from "../components/search/SearchByKeyword/SearchByKeyword"
import WebViewScreen from "../components/common/WebViewScreen"
import TutorialService from "../components/tutorial/TutorialService"
import Entry from "../components/entry/Entry"
import Main from "../components/main/Main"
import Gnb from "../components/common/Gnb"

/* Tutorial Pages */
import Intro from "../components/tutorial/Intro"
import Rating from "../components/tutorial/Rating"
import Result from "../components/tutorial/Result"

class MainRouter extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div id="wrapper">
            <Route exact path="/">
              <Entry />
            </Route>
            <Route path="/home" component={Home} />
            <Route
              path="/search"
              render={({ match: { url } }) => (
                <>
                  <Route path={`${url}`} component={Search} exact />
                  <Route path={`${url}/:keyword`} component={SearchResult} />
                </>
              )}
            />
            <Route path="/search_by_keyword" component={SearchByKeyword} />
            <Route path="/detail/:productID" component={Detail} />
            <Route path="/webview" component={WebViewScreen} />
            <Route path="/ranking" component={Ranking} />
            <Route path="/new_comment/:productID" component={NewComment} />
            <Route path="/login" component={Login} />
            <Route
              path="/tutorial"
              render={({ match: { url } }) => (
                <>
                  <TutorialService />
                  <Route path={`${url}/intro`} component={Intro} />
                  <Route path={`${url}/rating`} component={Rating} />
                  <Route path={`${url}/result`} component={Result} />
                </>
              )}
            />
            <ModalWrapper />
          </div>
          <Navigation />
        </Router>
      </Provider>
    )
  }
}

export default MainRouter
