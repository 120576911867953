import React from "react"

import classNames from "classnames/bind"
import styles from "../../../styles/Main.module.scss"

import RankingItems from "./RankingItems"

class Ranking extends React.Component {
  state = { top3: [] }

  render() {
    return (
      <div className={cn("ranking")}>
        <div className={cn("section_title")}>
          <div className={cn("section_title_text")}>스낵팟 랭킹</div>
          <div className={cn("space")}></div>
          {/* <div className={cn("critetia")}>
            <div className={cn("criteria_btn", "selected")}>
              <span>판매</span>
            </div>
            <div className={cn("criteria_btn")}>
              <span>평점</span>
            </div>
            <div className={cn("criteria_btn")}>
              <span>화제</span>
            </div>
            <div className={cn("criteria_btn")}>
              <span>할인</span>
            </div>
          </div> */}
        </div>
        <div className={cn("ranking_page_track")}>
          <RankingItems />
          <RankingItems />
          <RankingItems />
        </div>
      </div>
    )
  }
}

const cn = classNames.bind(styles)

export default Ranking
