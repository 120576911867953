import React from "react"

import classNames from "classnames/bind"
import styles from "../../../styles/Main.module.scss"

import ChoiceSlider from "./ChoiceSlider"

const cn = classNames.bind(styles)

function Choice(props) {
  return (
    <div className={cn("choice")}>
      <div className={cn("section_title")}>
        <div className={cn("section_title_text")}>스낵팟 초이스</div>
      </div>
      <ChoiceSlider />
    </div>
  )
}

export default Choice
