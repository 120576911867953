import React, { Component } from "react"

import classNames from "classnames/bind"
import styles from "../../styles/Detail_n.module.scss"

import BackButton from "../common/BackButton"

export class TopNavigation extends Component {
  render() {
    return (
      <div className={cn("top_nav")}>
        <BackButton />
        <div className={cn("like_btn")}>
          <img src="/images/like_filled.svg" alt="" />
        </div>
      </div>
    )
  }
}

const cn = classNames.bind(styles)

export default TopNavigation
