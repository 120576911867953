import React from "react"

import classNames from "classnames/bind"
import styles from "../../../styles/Main.module.scss"

import ReviewSlider from "./ReviewSlider"

const cn = classNames.bind(styles)

const Review = props => {
  return (
    <div className={cn("review")}>
      <div className={cn("section_title")}>
        <div className={cn("section_title_text")}>스낵팟 리뷰</div>
      </div>
      <ReviewSlider />
    </div>
  )
}

export default Review
