import React, { Component } from "react"

import classNames from "classnames/bind"
import styles from "../../styles/Search.module.scss"
import { Link } from "react-router-dom"

import MESSAGE from "../../constants/message"

import { groupByDiscount, groupByBrand, groupByCategory } from "../../constants/searchIconGroup"
import SearchIcons from "./SearchIcons"

export class Search extends Component {
  render() {
    return (
      <>
        <Link to="/search_by_keyword">
          <section className={cn("main_search_bar")}>
            <div className={cn("search_icon")}>
              <img src="/images/search.svg" alt="" />
            </div>
            <div className={cn("text_field")}>
              <span>{MESSAGE.SEARCHBAR_PLACEHOLDER}</span>
            </div>
            <div className={cn("barcode_icon")}>
              <img src="/images/barcode_search.svg" alt="" />
            </div>
          </section>
        </Link>

        <div style={{ height: 80 }}></div>

        <SearchIcons title="행사상품 찾아보기" icons={groupByDiscount} />
        <SearchIcons title="브랜드" icons={groupByBrand} />
        <SearchIcons title="카테고리" icons={groupByCategory} />
      </>
    )
  }
}

const byCategory = {
  아이스크림: "/images/search/icecream.svg",
  머핀: "/images/search/muffin.svg",
  쿠키: "/images/search/cookie.svg",
  캔디: "/images/search/candy.svg",
  음료: "/images/search/beverage.svg",
}
const cn = classNames.bind(styles)

export default Search
