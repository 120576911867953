import React, { useEffect } from "react"

import classNames from "classnames/bind"
import styles from "../../styles/Home_200403.module.scss"

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {/* <header className={cn("logoHeader")}>
        <div className={cn("logoImage")}>
          <img src="/images/logo_full.svg" />
        </div>
      </header> */}

      <div>
        <iframe src="https://snackpot.tistory.com/" title="snackpot" style={{ width: "100%", height: "200vh", border: 0 }} />
      </div>

      {/* <div className={cn("contents")}>
        <section className={cn("mainBanner")}>
          <img src="/images/home/main-banner.png" />
        </section>
        <section className={cn("miniBanner")}>
          <img src="/images/home/cvs.png" />
        </section>
        <section className={cn("theme")}>
          <div className={cn("themeElement")}>
            <div className={cn("themeImage")}>
              <img src="/images/home/strawberry.png" />
            </div>
            <div className={cn("themeInfo")}>
              <div className={cn("themeSectionText")}>테마</div>
              <div className={cn("themeTitleText")}>SNACKPOT 딸기 특집</div>
              <div className={cn("themeContentText")}>
                봄은 딸기의 계절! <br />
                음료부터 과자까지 딸기맛 스낵을 준비했습니다.
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className={cn("contents_post")}>
        <div className={cn("section_title")}>
          <span className={cn("post_text")}>포스트</span>
          <span className={cn("more")}>더보기 &gt;</span>
        </div>

        <div className={cn("postElement")}>
          <div className={cn("postImage")}>
            <img src="/images/home/pringles.png" />
          </div>
          <div className={cn("postInfo")}>
            <div className={cn("postTitleText")}>
              여러분은
              <br />
              지금까지 잘못 드시고 계셨습니다.
            </div>
            <div className={cn("postContentText")}>과자를 먹는 올바른 방법 : 프링글스편</div>
          </div>
        </div>

        <div className={cn("postElement")}>
          <div className={cn("postImage")}>
            <img src="/images/home/brsp.png" />
          </div>
          <div className={cn("postInfo")}>
            <div className={cn("postTitleText")}>기억하시나요?</div>
            <div className={cn("postContentText")}>학교 앞 불량식품 다시 먹어보기</div>
          </div>
        </div>
      </div> */}
    </>
  )
}

const cn = classNames.bind(styles)

export default Home
