import React from "react"

import classNames from "classnames/bind"
import styles from "../../styles/Detail_n.module.scss"
import TagItem from "./TagItem"

import { connect } from "react-redux"

class ProductTags extends React.Component {
  render() {
    const { tags } = this.props

    return (
      <div className={cn("product_tags_and_share")}>
        <div className={cn("product_tags")}>
          {tags.map((item, index) => (
            <TagItem tagName={item} key={index} />
          ))}
        </div>
        <div className={cn("share_icon")}></div>
        <div className={cn("pot_icon")}></div>
      </div>
    )
  }
}

const cn = classNames.bind(styles)

const mapStateToProps = (state) => ({
  // tags: state.detail.tags,
  tags: ["달콤", "초코", "부드러운"],
  discountTags: state.detail.discountTags,
})

export default connect(mapStateToProps)(ProductTags)
