import createReducer from "../common"
import api from "../../api/index"
import DETAIL_INITIAL_STATE from "./state"
import _ from "lodash"

const id = "search_result"

/* Action Constants */
const SET_KEYWORD = `${id}/SET_KEYWORD`
const SET_RESULTS = `${id}/SET_RESULTS`
const APPEND_RESULTS = `${id}/APPEND_RESULTS`
const SORT_RESULTS = `${id}/SORT_RESULTS`
const SET_IS_LOADING = `${id}/SET_IS_LOADING`
const SET_HAS_MORE = `${id}/SET_HAS_MORE`
const SET_ORDER_BY = `${id}/SET_ORDER_BY`
const SET_OFFSET = `${id}/SET_OFFSET`
const SET_SEARCH_CONDITION_QUERY_STR = `${id}/SET_SEARCH_CONDITION_QUERY_STR`
const SET_SCROLL_TOP = `${id}/SET_SCROLL_TOP`
const INCREASE_OFFSET = `${id}/INCREASE_OFFSET`
const DECREASE_OFFSET = `${id}/DECREASE_OFFSET`

/* Actions Creators */
export const setKeyword = keyword => ({ type: SET_KEYWORD, keyword })
export const setResults = (resultCount, results) => ({ type: SET_RESULTS, resultCount, results })
export const appendResults = results => ({ type: APPEND_RESULTS, results })
export const sortResults = criteria => ({ type: SORT_RESULTS, criteria })
export const setIsLoading = isLoading => ({ type: SET_IS_LOADING, isLoading })
export const setHasMore = hasMore => ({ type: SET_HAS_MORE, hasMore })
export const setOrderBy = orderBy => ({ type: SET_ORDER_BY, orderBy })
export const setOffset = offset => ({ type: SET_OFFSET, offset })
export const setSearchConditionQueryStr = searchConditionQueryStr => ({
  type: SET_SEARCH_CONDITION_QUERY_STR,
  searchConditionQueryStr,
})
export const setScrollTop = scrollTop => ({ type: SET_SCROLL_TOP, scrollTop })
export const increaseOffset = () => ({ type: INCREASE_OFFSET })
export const decreaseOffset = () => ({ type: DECREASE_OFFSET })

/* Thunk Middlewares */

/* the initial configuration for search */
export const initSearch = (keyword, searchConditionQueryStr) => dispatch => {
  dispatch(setKeyword(keyword))
  dispatch(setSearchConditionQueryStr(searchConditionQueryStr))
  dispatch(setIsLoading(true))
  dispatch(setOffset(0))
}

/* When Entering Search Result Page With New Keywords */
export const getResults = () => async (dispatch, getState) => {
  const {
    keyword,
    searchConditionQueryStr,
    searchConfig: { orderBy, offset, limit },
  } = getState().search_result

  return api
    .searchItem(keyword, { orderBy, offset, limit }, searchConditionQueryStr)
    .then(res => {
      const { resultCount, result } = res.data

      dispatch(setResults(resultCount, result))
      dispatch(setIsLoading(false))

      if (resultCount > 30) dispatch(setHasMore(true))
    })
    .catch(() => dispatch(setResults(0, [])))
    .finally(() => dispatch(setIsLoading(false)))
}

export const getMoreResults = () => async (dispatch, getState) => {
  dispatch(increaseOffset())

  const {
    hasMore,
    keyword,
    searchConditionQueryStr,
    searchConfig: { orderBy, offset, limit },
  } = getState().search_result

  if (!hasMore) return

  return api
    .searchItem(keyword, { orderBy, offset, limit }, searchConditionQueryStr)
    .then(res => {
      const { result } = res.data

      dispatch(appendResults(result))
    })
    .catch(() => {
      dispatch(setHasMore(false))
    })
}

/* Reducer */
const reducer = createReducer(DETAIL_INITIAL_STATE, {
  [SET_KEYWORD]: (state, action) => (state.keyword = action.keyword),
  [SET_RESULTS]: (state, action) => {
    state.results = action.results
    state.resultCount = action.resultCount
  },
  [APPEND_RESULTS]: (state, action) => (state.results = [...state.results, ...action.results]),
  [SORT_RESULTS]: (state, action) => (state.results = _.sortBy(state.results, i => i[action.criteria])),
  [SET_IS_LOADING]: (state, action) => (state.isLoading = action.isLoading),
  [SET_HAS_MORE]: (state, action) => (state.hasMore = action.hasMore),
  [SET_ORDER_BY]: (state, action) => (state.searchConfig.orderBy = action.orderBy),
  [SET_OFFSET]: (state, action) => (state.searchConfig.offset = action.offset),
  [SET_SEARCH_CONDITION_QUERY_STR]: (state, action) => (state.searchConditionQueryStr = action.searchConditionQueryStr),
  [SET_SCROLL_TOP]: (state, action) => (state.scrollTop = action.scrollTop),
  [INCREASE_OFFSET]: state => (state.searchConfig.offset = state.searchConfig.offset + state.searchConfig.limit),
  [DECREASE_OFFSET]: state => (state.searchConfig.offset = state.searchConfig.offset - state.searchConfig.limit),
})

export default reducer
