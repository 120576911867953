import { MAX_NUMBER_OF_PRODUCTS } from "../../constants/tutorial"

const initialSelectedProduct = new Array(MAX_NUMBER_OF_PRODUCTS).fill(0.0)

const INITIAL_STATE = {
  name: "",
  gender: "",
  ageSpan: "",
  productSelection: initialSelectedProduct,
  entranceTime: 0,
  finished: false,
  selectedCount: 0,
  canceledCount: 0,
}

export default INITIAL_STATE