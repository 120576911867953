import React from "react"

import SearchBar from "../SearchBar"
import RecentKeywords from "./RecentKeywords/RecentKeywords"
import { connect } from "react-redux"
import { setKeyword } from "../../../store/search_result"

class SearchByKeyword extends React.Component {
  componentDidMount() {
    this.props.dispatch(setKeyword(""))
  }

  render() {
    return (
      <>
        <SearchBar />
        <RecentKeywords />
      </>
    )
  }
}

export default connect()(SearchByKeyword)
