import React from "react"

import classNames from "classnames/bind"
import styles from "../../../styles/Detail.module.scss"
import { Link } from "react-router-dom"
import { connect } from "react-redux"

import MESSAGE from "../../../constants/message"

class CommentWriteButton extends React.Component {
  state = {}

  goToWriteReviewPage = e => {
    const { authenticated } = this.props

    if (!authenticated) {
      e.preventDefault()
      alert(MESSAGE.NEW_COMMENT_WRITE_NEED_LOGIN)
    }
  }

  render() {
    const { productID } = this.props

    return (
      <Link to={`../new_comment/${productID}`}>
        <div className={cn("new_comment_btn_wrapper")}>
          <div className={cn("new_comment_btn")} onClick={this.goToWriteReviewPage}>
            <div>작성하기</div>
          </div>
        </div>
      </Link>
    )
  }
}

const cn = classNames.bind(styles)

const mapStateToProps = state => ({
  productID: state.detail.productID,
  authenticated: state.auth.authenticated,
})

export default connect(mapStateToProps)(CommentWriteButton)
