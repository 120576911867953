import React from "react"

import classNames from "classnames/bind"
import styles from "../../styles/WebviewScreen.module.scss"

import queryString from "query-string"
import { useHistory, useLocation } from "react-router-dom"

const WebViewScreen = ({ title, showBackBtn, showCloseBtn }) => {
  showBackBtn = true
  showCloseBtn = true

  const history = useHistory()
  const location = useLocation()

  const queryURL = queryString.parse(location.search).url

  const goBack = () => {
    history.goBack()
  }

  return (
    <>
      <div className={cn("title_bar")}>
        {showBackBtn && <img className={cn("back_btn")} src="images/back_btn.svg" alt="" onClick={goBack} />}
        <span className={cn("title_bar_text")}>{title || "유니콘 휠 스트로베리 마쉬멜로 비스킷"}</span>
        {showCloseBtn && <img className={cn("close_btn")} src="images/close_btn.svg" alt="" />}
      </div>
      <div className={cn("contents")}>
        <iframe title="webview" src={queryURL || "https://m.blog.naver.com/steven30/221796557669"} allowFullScreen={true}></iframe>
      </div>
    </>
  )
}

const cn = classNames.bind(styles)

export default WebViewScreen
