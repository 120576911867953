import React from "react"

import classNames from "classnames/bind"
import styles from "../../styles/Detail_n.module.scss"
import { connect } from "react-redux"

import { getActualProductName, thousandSeparator } from "../../util/index"

import DiscountTags from "./DiscountTags"
import { setShowModal } from "../../store/common/index"
import RatingStarImages from "../common/RatingStarImages"

class ProductBasicInfo extends React.Component {
  render() {
    const { manufacturer, brandLine, nameKor, price, image_front, flavor, flavorPosition } = this.props.productInfo
    const { rating, ratedCount } = this.props.productEvaluationInfo
    const actualProductName = getActualProductName(nameKor, flavor, flavorPosition)

    return (
      <section className={cn("product_info")}>
        <div
          className={cn("product_image")}
          onClick={() => {
            this.props.dispatch(setShowModal(true, "LoginModal"))
          }}
        >
          <img src={image_front || "/images/image_unavailable.png"} alt="" />
        </div>
        <div className={cn("product_name")}>
          <div className={cn("brand")}>
            <div className={cn("manufacturer")}>{manufacturer}</div>
            <div className={cn("brand_line")}>{brandLine}</div>
          </div>
          <div className={cn("name")}>{actualProductName}</div>
        </div>

        <div className={cn("product_rating")}>
          <div className={cn("stars")}>
            <RatingStarImages rating={rating} />
          </div>
          <div className={cn("rating_num")}>{rating}</div>
          <div className={cn("rated_count")}>{ratedCount}</div>
        </div>

        {!!price && (
          <div className={cn("product_price")}>
            <span className={cn("price_text")}>{thousandSeparator(price) || ""}</span>
            <span className={cn("price_text_won")}>원</span>
          </div>
        )}

        <DiscountTags />
      </section>
    )
  }
}

const cn = classNames.bind(styles)

const mapStateToProps = state => {
  return {
    productID: state.detail.productID,
    productInfo: state.detail.productInfo,
    productEvaluationInfo: state.detail.productEvaluationInfo,
  }
}

export default connect(mapStateToProps)(ProductBasicInfo)
