import React from "react"

import { connect } from "react-redux"
import { getProductInfo, getComments, getProductEvaluationInfo } from "../../store/detail/index"

import DeviceMargin from "../common/DeviceMargin"
import TopNavigation from "./TopNavigation"
import ProductBasicInfo from "./ProductBasicInfo"
import UserComments from "./UserComments/UserComments"
import Videos from "./Videos"
import Loading from "../common/Loading"
import Tabs from "./Tabs"
import Tags from "./Tags"

class Detail extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props
    const { productID } = this.props.match.params

    window.scrollTo(0, 0)

    dispatch(getProductInfo(productID))
    dispatch(getComments(productID))
    dispatch(getProductEvaluationInfo(productID))
  }

  render() {
    const { isLoading } = this.props

    if (isLoading) return <Loading />
    else {
      return (
        <>
          <DeviceMargin height="40" />
          <TopNavigation />
          <ProductBasicInfo />
          <Tags />
          <UserComments />
        </>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.detail.isLoading,
  userID: state.auth.userID,
})

export default connect(mapStateToProps)(Detail)
