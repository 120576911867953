import React, { Component } from "react"

export class Loading extends Component {
  state = {
    curImage: 0,
  }

  tick = () => {
    const newOrder = (this.state.curImage + 1) % 4

    this.setState({ curImage: newOrder }, () => {})
  }

  componentDidMount() {
    this.timer = setInterval(() => this.tick(), 300)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render() {
    const curImage = String(this.state.curImage)

    return (
      <>
        <div style={{ height: "100vh" }}></div>
        <div style={styles.div}>
          <img src={`/images/loading/${curImage}.png`} alt="" style={styles.img} />
        </div>
      </>
    )
  }
}

const styles = {
  div: {
    width: "100%",
    height: "100vh",
    position: "fixed",
    zIndex: "3",
    top: "0",
    left: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    width: "30px",
    height: "30px",
  },
}

export default Loading
