import React from "react"
import { useDispatch } from "react-redux"
import { setShowModal } from "../../../../store/common/index"

import classNames from "classnames/bind"
import styles from "../../../../styles/Modal.module.scss"

const LoginModal = () => {
  const dispatch = useDispatch()

  return (
    <div className={cn("modal-login")}>
      <div
        className={cn("close_btn")}
        onClick={() => {
          dispatch(setShowModal(false))
        }}
      >
        <img src="/images/close_btn.svg" alt="" />
      </div>
      <div className={cn("splash_image")}>
        <img src="/images/modal/login_splash.png" />
      </div>
      <div className={cn("message")}>로그인이 필요합니다.</div>
      <div className={cn("login_btn")}>
        <span>로그인</span>
      </div>
    </div>
  )
}

const cn = classNames.bind(styles)

export default LoginModal
