import React from "react"

import classNames from "classnames/bind"
import styles from "../../styles/Ranking.module.scss"

import RankingItem from "./RankingItem"

class Ranking extends React.Component {
  state = {}
  render() {
    return (
      <>
        <div className={cn("title_bar_wrapper")}>
          <div className={cn("title_bar")}>
            <span>랭킹</span>
          </div>
        </div>

        <div className={cn("criteria_list")}>
          <div className={cn("criteria_selected")}>
            <span>판매</span>
          </div>
          <div className={cn("criteria")}>
            <span>평점</span>
          </div>
          <div className={cn("criteria")}>
            <span>할인</span>
          </div>
        </div>

        <div className={cn("ranking_list")}>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
            <RankingItem ranking={item} />
          ))}
        </div>
      </>
    )
  }
}

const cn = classNames.bind(styles)

export default Ranking
