import React from "react"

import classNames from "classnames/bind"
import styles from "../../styles/tutorial/rating.module.scss"

import { connect } from "react-redux"

import { setProductRating, increaseCanceledCount } from "../../store/tutorial"

import RatingSlider from "./Slider/RatingSlider"
import RatingStarImages from "./Slider/RatingStarImages"

export class Product extends React.Component {
  state = {
    selected: false,
    rating: 0.0,
  }

  setSelect = () => {
    this.setState({ selected: true })
  }

  unSelect = () => {
    const { dispatch, productID } = this.props

    this.setState({ selected: false, rating: 0.0 })
    dispatch(setProductRating(productID, 0.0))
    dispatch(increaseCanceledCount())
  }

  newRating = (e, value) => {
    this.setState({ rating: value })
  }

  newRatingMouseUp = (e, value) => {
    const { dispatch, productID } = this.props

    if (value === 0.0) {
      this.unSelect()
    } else {
      this.setSelect()
    }

    dispatch(setProductRating(productID, value))
  }

  render() {
    const { selected, rating } = this.state
    const { productID } = this.props
    const { productName, brandName } = this.props.productInfo

    const imgID = productID.padStart(3, "0")

    return (
      <div className={cn("list-item", selected && "list-item-selected")}>
        {selected && (
          <div className={cn("list-item-selected-checked")} onClick={() => this.unSelect()}>
            <img src="/images/tutorial/check.svg" className={cn("checked-img")} alt="" />
          </div>
        )}
        <div className={cn("product-image")}>
          <img src={`/images/tutorial/products/${imgID}.jpg`} alt="" />
        </div>
        <div className={cn("product-info")}>
          <div className={cn("brand-name")}>{brandName}</div>
          <div className={cn("product-name")}>{productName}</div>
          <div className={cn("rating-info")}>
            <div className={cn("rating-stars")}>
              <div className={cn("f-slider")}>
                <RatingSlider
                  min={0}
                  max={5.0}
                  step={0.5}
                  onChange={this.newRating}
                  onChangeCommitted={this.newRatingMouseUp}
                />
              </div>
              <div>
                <RatingStarImages rating={rating} />
              </div>
            </div>

            <div className={cn("rating-text")}>{rating.toFixed(1)}</div>
          </div>
        </div>
      </div>
    )
  }
}

const cn = classNames.bind(styles)

export default connect()(Product)
