import Cookies from "js-cookie"

export const addToRecentKeywordToCookie = keyword => {
  if (Cookies.get("recent") === undefined) {
    Cookies.set("recent", "|:|")
  }

  let currentKeywords = Cookies.get("recent")

  if (currentKeywords.indexOf("|:|" + keyword + "|:|") < 0) {
    Cookies.set("recent", currentKeywords + keyword + "|:|")
  } else {
    currentKeywords = currentKeywords.replace("|:|" + keyword + "|:|", "|:|")
    Cookies.set("recent", currentKeywords + keyword + "|:|")
  }
}

export const deleteRecentKeywordInCookie = keyword => {
  let currentKeywords = Cookies.get("recent")

  Cookies.set("recent", currentKeywords.replace("|:|" + keyword + "|:|", "|:|"))
}

export const deleteAllRecentKeywordsInCookie = () => {
  Cookies.remove("recent")
}

export const getRecentKeywordsFromCookie = () => {
  const keywordsInCookie = Cookies.get("recent")

  if (keywordsInCookie !== undefined) {
    const tempRecentKeywords = keywordsInCookie.split("|:|")

    return tempRecentKeywords.filter(kwd => kwd.length > 0)
  }
}
