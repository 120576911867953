import React from "react"

import { calculateNumberOfStars } from "../../../util/index"


const RatingStarImages = ({ rating }) => {
  const numberOfStars = calculateNumberOfStars(rating)
  const starImages = []

  for (let i = 0; i < numberOfStars.numberOfFullStars; i++) starImages.push(<img src="/images/tutorial/star_full.svg" alt="star" />)
  for (let i = 0; i < numberOfStars.numberOfHalfStars; i++) starImages.push(<img src="/images/tutorial/star_half.svg" alt="star" />)
  for (let i = 0; i < numberOfStars.numberOfEmptyStars; i++) starImages.push(<img src="/images/tutorial/star_empty.svg" alt="star" />)

  return <>{starImages}</>
}

export default RatingStarImages
