import React from "react"

import classNames from "classnames/bind"
import styles from "../../styles/tutorial/result.module.scss"

import { connect } from "react-redux"

import api from "../../api"
import clusterList from "../../constants/tutorial/clusterList"
import { getAverageTaste, getClutser } from "../../util"
// import ResultChart from "./ResultChart"
import { finishTutorialRequest } from "../../store/auth"

export class Result extends React.Component {
  state = {
    cluster: {},
  }

  componentDidMount() {
    const {
      userID,
      gender,
      ageSpan,
      productSelection,
      selectedCount,
      canceledCount,
      entranceTime,
      dispatch
    } = this.props

    const elapsedTime = (new Date() - entranceTime) / 1000
    const clusterNumber = getClutser(productSelection)

    this.setState({ cluster: clusterList[clusterNumber] })

    api.sendTutorialResult(userID, gender, ageSpan, elapsedTime, clusterNumber, selectedCount, canceledCount, productSelection)
    .then(() => {
      dispatch(finishTutorialRequest())
    })
    
  }

  render() {
    const { cluster } = this.state

    return (
      <>
        <div className={cn("upper")} style={{ backgroundColor: cluster.bgColor }}>
          <div className={cn("top-title")}>나의 스낵성향 분석결과</div>
          <div className={cn("type-text")}>{cluster.name}</div>
          <div className={cn("type-img")}>
            <img src={`/images/illustrations/${cluster.clusterID}.svg`} alt="" />
          </div>
          <div className={cn("detail-type")}>
            <div className={cn("dt1")}>{cluster.preferFlavor}</div>
            <div className={cn("dt2")}>{cluster.party}</div>
          </div>
        </div>

        <div className={cn("bottom")}>
          <div className={cn("bottom-content")}>
            <div className={cn("btm-top-title")}>{cluster.name} 특징은?</div>
            <div className={cn("btm-type-text")}>{cluster.typeText}</div>
          </div>
          <div className={cn("bottom-content")}>
            <div className={cn("btm-top-title")}>내 스낵 성향은?</div>
            {/* {cluster.bgColor && <ResultChart bgColor={cluster.bgColor} />} */}
          </div>
        </div>
      </>
    )
  }
}


const mapStateToProps = state => ({
  userID: state.auth.userID,
  gender: "-1",
  ageSpan: "-1",
  selectedCount: state.tutorial.selectedCount,
  canceledCount: state.tutorial.canceledCount,
  productSelection: state.tutorial.productSelection,
  entranceTime: state.tutorial.entranceTime,
})

const cn = classNames.bind(styles)

export default connect(mapStateToProps)(Result)
