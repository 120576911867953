import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"

import { getAuthInfo } from "../../store/auth"
import { HTTP_CODE } from "../../constants/api"
import { TUTORIAL_STATUS } from "../../constants/tutorial"

/* 사용자가 앱을 실행했을 때 처음으로 도달하게 되는 컴포넌트 */

const Entry = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(getAuthInfo())
      /* 기존 가입자인 경우 */
      .then((res) => {
        const { tutorial } = res.user

        switch (tutorial) {
          /* 튜토리얼 한 번도 안 한 경우 --> 튜토리얼 화면으로 이동 */
          case 0:
            history.push("/tutorial/intro")
            break

          /* 튜토리얼 완료했던 유저의 경우 --> 메인화면으로 이동 */
          case 1:
            history.push("/home")
            break

          /* 튜토리얼 건너뛰었던 유저의 경우 --> 메인화면으로 이동 */
          case 2:
            history.push("/home")
            break

          default:
        }
      })

      /* 미가입자인 경우 or 로그인 안 되어 있는 경우 --> 로그인 화면으로 이동 */
      .catch((res) => {
        const { status } = res.response

        if (!res.response) history.push("/login")
        if (status === HTTP_CODE.UNAUTHORIZED) history.push("/login")
      })
  }, [dispatch, history])

  return <></>
}

export default Entry
