import React from "react"

import classNames from "classnames/bind"
import styles from "../../../styles/Main.module.scss"

import { Link } from "react-router-dom"

class SearchBar extends React.Component {
  state = {}

  executeImageSearch = e => {
    alert("image")
  }

  executeBarcodeSearch = e => {
    alert("barcode")
  }

  render() {
    return (
      <div className={cn("search_bar")}>
        <Link to="/search">
          <div className={cn("search_input")}>
            <div className={cn("search_btn")}>
              <img src="/images/search.png" alt="검색" />
            </div>

            <div className={cn("space")}></div>

            <div className={cn("image_search_btn")} onClick={this.executeImageSearch}>
              <img src="/images/camera.png" alt="카메라 검색" />
            </div>
            <div className={cn("barcode_search_btn")} onClick={this.executeBarcodeSearch}>
              <img src="/images/barcode.png" alt="바코드 검색" />
            </div>
          </div>
        </Link>
      </div>
    )
  }
}

const cn = classNames.bind(styles)

export default SearchBar
