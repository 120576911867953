import React from "react"

import { Link } from "react-router-dom"

import { connect } from "react-redux"
import { getAuthInfo } from "../../store/auth"

import URL from "../../constants/url"

import classNames from "classnames/bind"
import styles from "../../styles/Login.module.scss"
class Login extends React.Component {
  state = {
    user: {},
    show: true,
  }

  componentDidMount() {
    const { dispatch } = this.props

    dispatch(getAuthInfo())
  }

  render() {
    return (
      <>
        <div className={cn("video-bg")}>
          <video autoPlay loop muted playsInline>
            <source src="/login-crop.mp4" type="video/mp4" />
          </video>
          <div className={cn("black-opacity")}></div>
        </div>
        <div className={cn("logo")}>
          <img src="/images/logo.png" alt="" style={{ display: "block", margin: "auto", width: "240px" }} />
        </div>
        <section className={cn("btns")}>
          <div className={cn("info_text")}>
            새로운 스낵 소비 문화를 제시하는
            <br />
            스낵 검색・추천 플랫폼
          </div>
          <a href={`${URL.AUTH_URL}/auth/login/kakao`}>
            <div className={cn("kakao")}>
              <span className={cn("text")}>카카오 계정으로 로그인</span>
            </div>
          </a>
          <a href={`${URL.AUTH_URL}/auth/login/naver`}>
            <div className={cn("naver")}>
              <span className={cn("text")}>네이버 아이디로 로그인</span>
            </div>
          </a>
          <div className={cn("facebook")}>
            <span className={cn("text")}>페이스북으로 로그인</span>
          </div>
          <Link to="/home">
            <div className={cn("without_login")}>
              <span className={cn("text")}>로그인없이 둘러보기</span>
            </div>
          </Link>
          <a href={`${URL.AUTH_URL}/auth/logout`}>로그아웃</a>

          <Link to="/tutorial">튜토리얼</Link>
          <span>
            현재 로그인 상태: {this.props.authenticated && `[${this.props.provider}] ${this.props.displayName}`}
          </span>
        </section>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  displayName: state.auth.user.displayName,
  provider: state.auth.user.provider,
})

const cn = classNames.bind(styles)

export default connect(mapStateToProps)(Login)
