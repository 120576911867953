import URL from "./url"

export const apiURL = {
  GET_PRODUCT_INFO: id => `${URL.API_URL}/product/${id}`,
  GET_PRODUCT_INFO_V2: id => `${URL.API_URL}/product/v2/${id}`,
  GET_TAG_INFO: id => `${URL.API_URL}/product/tag/${id}`,
  GET_PRODUCT_EVALUATION_INFO: productID => `${URL.API_URL}/evaluation/${productID}`,
  GET_IMAGE_LIST: id => `${URL.API_URL}/product/imagelist/${id}`,
  GET_RANKING_TOP3: () => `${URL.API_URL}/ranking/top3`,

  INCREASE_READ_COUNT: id => `${URL.API_URL}/rating/read/${id}`,
  INCREASE_LIKE_COUNT: id => `${URL.API_URL}/rating/like/${id}`,

  GET_PRODUCT_RATING: id => `${URL.API_URL}/evaluation/rating/${id}`,
  SET_PRODUCT_RATING: id => `${URL.API_URL}/evaluation/rating/${id}`,
  GET_PRODUCT_LIKE: id => `${URL.API_URL}/evaluation/like/${id}`,
  INCREASE_PRODUCT_LIKE: productID => `${URL.API_URL}/evaluation/like/${productID}`,
  CANCEL_PRODUCT_LIKE: productID => `${URL.API_URL}/evaluation/like/${productID}`,

  SEARCH_ITEM: keyword => `${URL.API_URL}/search/${keyword}`,

  NEW_COMMENT: productID => `${URL.API_URL}/comment/${productID}`,
  GET_COMMENT: productID => `${URL.API_URL}/comment/${productID}`,
  GET_COMMENT_LIKE: commentID => `${URL.API_URL}/comment/like/${commentID}`,
  INCREASE_COMMENT_LIKE: commentID => `${URL.API_URL}/comment/like/${commentID}`,
  CANCEL_COMMENT_LIKE: commentID => `${URL.API_URL}/comment/like/${commentID}`,

  GET_AUTH_INFO: () => `${URL.AUTH_URL}/auth`,
  FINISH_TUTORIAL: () => `${URL.API_URL}/tutorial/finish`,
  SKIP_TUTORIAL: () => `${URL.API_URL}/tutorial/skip`,
  SEND_TUTORIAL_RESULT: () => `${URL.API_URL}/viral/rating`,
}

export const HTTP_CODE = {
  OK: 200,
  NO_CONTENTS: 204, //성공적으로 처리했지만 컨텐츠를 제공하지는 않는다.
  BAD_REQUEST: 400, // 올바르지 않은 클라이언트 요청 : DB의 FK constraint violation 등
  UNAUTHORIZED: 403, //권한 없음
  NOT_FOUND: 404, //찾는 리소스 없음
  SERVER_ERROR: 500, //서버 오류
}
