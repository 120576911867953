import React from "react"

import classNames from "classnames/bind"
import styles from "../../styles/Search.module.scss"

import { Link } from "react-router-dom"

const SearchIcons = ({ title, icons }) => {
  const numOfPseudoItems = 4 - (icons.length % 4)
  const pseudoItems = []

  for (let i = 0; i < numOfPseudoItems; i++) pseudoItems.push(<div className={cn("icon")}></div>)

  return (
    <section className={cn("section_group")}>
      <div className={cn("section_title")}>{title}</div>
      <div className={cn("section_icons")}>
        {icons.map((item, index) => (
          <div className={cn("icon")} key={index}>
            <Link to={item.url}>
              <div className={cn("icon_img")}>
                <img src={item.icon} alt={item.name} />
              </div>
              <div className={cn("icon_text")}>{item.name}</div>
            </Link>
          </div>
        ))}
        {pseudoItems}
      </div>
    </section>
  )
}

const cn = classNames.bind(styles)

export default SearchIcons
