const INITIAL_STATE = {
  keyword: "",
  resultCount: -1,
  results: [],
  isLoading: true,
  hasMore: false,
  scrollTop: 0,

  searchConfig: {
    /* orderBy can have the following values: [score, rating, ratedCount, likeCount] */
    orderBy: "score",

    offset: 0,
    limit: 30,
  },
  searchConditionQueryStr: "",
}

export default INITIAL_STATE
