import productList from "../constants/tutorial/productList"
import clusterList from "../constants/tutorial/clusterList"

export const shuffleArray = arr =>
  arr
    .map(a => [Math.random(), a])
    .sort((a, b) => a[0] - b[0])
    .map(a => a[1])

export const countVaildRatings = list => {
  let sum = 0

  for (let item in list) {
    if (list[item] >= 0.5) sum++
  }

  return sum
}

export function getDateFromTimestamp(timestamp) {
  return timestamp.substring(0, 10)
}


export function calculateNumberOfStars(rating) {
  const numberOfFullStars = Math.floor(rating)
  const numberOfHalfStars = Math.ceil(rating) - Math.floor(rating)
  const numberOfEmptyStars = 5 - Math.ceil(rating)

  return { numberOfFullStars, numberOfHalfStars, numberOfEmptyStars }
}


export function thousandSeparator(n) {
  if (n) {
    n = String(n)
    var s = n.split(".")[1]

    s ? (s = "." + s) : (s = "")
    n = n.split(".")[0]

    while (n.length > 3) {
      s = "," + n.substr(n.length - 3, 3) + s
      n = n.substr(0, n.length - 3)
    }

    return n + s
  } else {
    return ""
  }
}


export function getActualProductName(nameKor, flavor, flavorPosition) {
  return `${nameKor}`
}


export const getAverageTaste = list => {
  if (!list) return

  const resultTaste = { spicy: 0, sweety: 0, salty: 0, savory: 0, sour: 0 }
  const sumOfRatings = list.reduce((a, b) => a + b, 0)

  list.forEach((value, index, array) => {
    if (value) {
      for (const taste in resultTaste) {
        resultTaste[taste] = resultTaste[taste] + value * productList[index][taste]
      }
    }
  })

  for (const item in resultTaste) resultTaste[item] = (resultTaste[item] / sumOfRatings) * 10

  return Object.values(resultTaste)
}


export const getClutser = list => {
  if (!list) return

  const numOfEachCluster = [0, 0, 0, 0, 0, 0, 0, 0, 0]
  let resultClusterIndex = 0
  let resultClusterNum = 0

  list.forEach((value, index) => {
    if (value >= 4 && value <= 5) {
      const clusterIndex = productList[index]["cluster"]

      numOfEachCluster[clusterIndex] = numOfEachCluster[clusterIndex] + (value * clusterList[clusterIndex]["scoreWeight"])
    }
  })

  for (let i = 0; i < numOfEachCluster.length; i++) {
    if (resultClusterNum < numOfEachCluster[i]) {
      resultClusterIndex = i
      resultClusterNum = numOfEachCluster[i]
    }
  }

  return resultClusterIndex
}