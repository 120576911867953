import React, { Component } from "react"
import { connect } from "react-redux"
import { getResults, setOrderBy } from "../../../store/search_result"

export class ResultOrder extends Component {
  handleValueChange = e => {
    const { keyword, dispatch } = this.props

    this.setState({ orderBy: e.target.value })

    dispatch(setOrderBy(e.target.value))
    dispatch(getResults(keyword))
  }

  render() {
    const { orderBy } = this.props

    return (
      <div>
        <select value={orderBy} onChange={this.handleValueChange}>
          <option value="productID">기본순</option>
          <option value="rating">평점높은순</option>
          <option value="ratedCount">평가횟수순</option>
          <option value="likeCount">좋아요순</option>
        </select>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  orderBy: state.search_result.searchConfig.orderBy,
  keyword: state.search_result.keyword,
})

export default connect(mapStateToProps)(ResultOrder)
