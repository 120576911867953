const MESSAGE = {
  TUTORIAL: {
    MAIN_TEXT: "좋아하는 과자 10개를 선택해 팟에 넣어보세요. 당신의 스낵 취향을 분석해드립니다.",
    POT_BUTTON_TOOLTIP: "팟버튼을 눌러<br />나의 스낵 취향을 확인해보세요!",
  },
  USER_COMMENTS: {
    SECTION_TITLE: "스낵 토크",
    MORE: "더보기",
  },
  NEW_COMMENT_PLACEHOLDER: "리뷰 내용을 입력해주세요.",
  NEW_COMMENT_WRITE_SUCCESS: "등록되었습니다.",
  NEW_COMMNET_FAIL_TO_WRITE: "오류로 인해 리뷰가 등록되지 않았습니다.",
  NEW_COMMENT_WRITE_NEED_LOGIN: "로그인이 필요합니다.",
  SEARCHBAR_PLACEHOLDER: "원하는 스낵을 찾아보세요.",
}

export default MESSAGE
