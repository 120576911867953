import React from "react"
import classNames from "classnames/bind"
import styles from "../../../styles/SearchResult_n.module.scss"

import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { initSearch, getResults, getMoreResults, setOrderBy } from "../../../store/search_result"

import { addToRecentKeywordToCookie } from "../../../util/recentKeywords"

import Item from "./Item"
import Loading from "../../common/Loading"

class ResultItems extends React.Component {
  handleInfiniteScrolling = (entries) => {
    const { hasMore, dispatch } = this.props

    if (entries[0].isIntersecting && hasMore) {
      dispatch(getMoreResults()).then(() => {
        this.io.disconnect()
        this.io.observe(getLastChildOfList())
      })
    }
  }

  io = new IntersectionObserver(this.handleInfiniteScrolling, {
    root: null,
    threshold: 1,
  })

  searchInitialization = () => {
    const { dispatch, location, match } = this.props

    const keywordQueryStr = match.params.keyword
    const searchConditionQueryStr = new URLSearchParams(location.search).toString()

    dispatch(initSearch(keywordQueryStr, searchConditionQueryStr))
    dispatch(getResults()).then(() => {
      if (getLastChildOfList()) this.io.observe(getLastChildOfList())
    })
  }

  cookieInitialization = () => {
    const { match } = this.props

    const keywordQueryStr = match.params.keyword
    addToRecentKeywordToCookie(keywordQueryStr)
  }

  setScrollTopOnBack = (scrollTop) => {
    const { history } = this.props

    if (history.action === "POP") document.getElementById("wrapper").scrollTop = scrollTop
  }

  sortResults = (criteria) => {
    const { dispatch } = this.props

    dispatch(setOrderBy(criteria))
    this.searchInitialization()
  }

  componentDidMount() {
    const { match } = this.props

    const keywordQueryStr = match.params.keyword
    const keywordFromState = this.props.keyword
    const { scrollTop } = this.props

    if (keywordQueryStr !== keywordFromState) {
      this.searchInitialization()
      this.cookieInitialization()
    } else {
      this.setScrollTopOnBack(scrollTop)
    }
  }

  componentDidUpdate() {
    const { match } = this.props

    const keywordQueryStr = match.params.keyword
    const keywordFromState = this.props.keyword

    if (keywordQueryStr !== keywordFromState) {
      console.log("lk")
      this.searchInitialization()
      this.cookieInitialization()
    }
  }

  render() {
    const { results, isLoading } = this.props

    if (isLoading) {
      return <Loading />
    } else {
      return (
        <>
          {/* <div onClick={() => this.sortResults("productID")}>정렬테스트</div> */}
          <section className={cn("result_items_list")} id="result_wrapper">
            {!!results.length && results.map((item, index) => <Item productInfo={item} key={index} />)}
          </section>
        </>
      )
    }
  }
}

const mapToStateProps = (state) => ({
  keyword: state.search_result.keyword,
  results: state.search_result.results,
  isLoading: state.search_result.isLoading,
  hasMore: state.search_result.hasMore,
  scrollTop: state.search_result.scrollTop,
})

const getLastChildOfList = () => document.querySelector("#result_wrapper > div:last-child")

const cn = classNames.bind(styles)

export default connect(mapToStateProps)(withRouter(ResultItems))
