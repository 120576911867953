import React from "react"

import classNames from "classnames/bind"
import styles from "../../styles/Detail.module.scss"
import { Link } from "react-router-dom"
import { connect } from "react-redux"

import { cancelProductLikeRequest, productLikeRequest } from "../../store/detail/index"

import RatingStarImages from "../common/RatingStarImages"

import fish_bowl from "../../assets/fish_bowl.svg"
import like_icon from "../../assets/like.svg"

class ProductEvaluation extends React.Component {
  state = {
    needReload: true,
  }

  handleProductLike = () => {
    const { productID } = this.props
    this.props.dispatch(productLikeRequest(productID))
    // if (authenticated) {
    //   if (!productEvaluationInfo.ifUserLikeProduct) this.props.dispatch(increaseProductLikeRequest(productID))
    //   else this.props.dispatch(cancelProductLikeRequest(productID))
    // } else {
    //   alert("로그인이 필요합니다.")
    // }
  }

  render() {
    const { rating, ratedCount, likeCount } = this.props.productEvaluationInfo
    const { productID } = this.props

    return (
      <div className={cn("product_rating")}>
        <Link to={"/new_comment/" + productID}>
          <div className={cn("stars")}>
            <RatingStarImages rating={rating} />
          </div>
          <span className={cn("rating")}>{rating}</span>
          <span className={cn("number_of_ratings")}>{ratedCount}</span>
        </Link>

        <div className={cn("push_margin")}></div>

        {/* 취향 일치 임시 삭제 */}
        {/* <div className={cn("taste")}>
          <img src={fish_bowl} alt="" />
          <span>취향일치</span>
        </div> */}

        <div className={cn("like")} onClick={this.handleProductLike}>
          <img src={like_icon} alt="" />
          <span>{likeCount}</span>
        </div>
      </div>
    )
  }
}

const cn = classNames.bind(styles)

const mapStateToProps = state => ({
  authenticated: state.auth.authenticated,
  userID: state.auth.userID,
  productID: state.detail.productID,
  productEvaluationInfo: state.detail.productEvaluationInfo,
})

export default connect(mapStateToProps)(ProductEvaluation)
