import React from "react"

import classNames from "classnames/bind"
import styles from "../../../styles/Main.module.scss"

class Logo extends React.Component {
  state = {}
  render() {
    return (
      <div className={cn("logo")}>
        <img src="/images/logo.png" alt="logo" />
      </div>
    )
  }
}
const cn = classNames.bind(styles)

export default Logo
