const clusterList = [
  {
    clusterID: 0,
    name: "짭짤이",
    preferFlavor: "전체적으로 담백하면서 살짝 짭짤",
    typeText:
      "과자 하면 생각나는 맛 좋아하는 스타일, 단체로 아이스크림 시킬 때 아 누가 비비빅 사오래 라는 소리 들을 스타일",
    hero: "캡틴 아메리카",
    character: "근본주의",
    party: "교수님파",
    job: "선비",
    bgColor: "#ffd269",
    scoreWeight: "9",
  },
  {
    clusterID: 1,
    name: "달콤이",
    preferFlavor: "부드럽고 고소한 단맛",
    typeText: "틀니 압수해도 씹을 수 있을 맛, 과자는 무적권 디저트야 파",
    hero: "아이언맨",
    character: "티타임",
    party: "새내기파",
    job: "수다형",
    bgColor: "#ffa1a1",
  },
  {
    clusterID: 2,
    name: "담백이",
    preferFlavor: "담백, 감칠맛",
    typeText: "입이 심심할 때 얌얌긋, 하나씩 집어먹다가 배 채울 스타일, 그래도 삼시세끼 거르지 않을 스타일",
    hero: "호크아이",
    character: "뒤끝없는",
    party: "담백파",
    job: "심플",
    bgColor: "#ffc77f",
  },
  {
    clusterID: 3,
    name: "기영이",
    preferFlavor: "고소한맛",
    typeText: "칩이 좋아, 달콤한 도박, 오링 났어 맛, 구황작물 성애자, 악몽 꿀 때 감자 기근 나오는 스타일",
    hero: "그린랜턴",
    character: "칩",
    party: "농작물파",
    job: "농부",
    bgColor: "#ffb84b",
  },
  {
    clusterID: 4,
    name: "유학파",
    preferFlavor: "버터 맛, 느끼한 맛",
    typeText: "미국 어디에 떨어트려놔도 대한항공 고추장 안챙길 스타일",
    hero: "슈퍼맨",
    character: "고소,버터,느끼, 기름",
    party: "교포",
    job: "외국인",
    bgColor: "#d4c664",
  },
  {
    clusterID: 5,
    name: "핵달이",
    preferFlavor: "쫀득쫀득하게 단 맛",
    typeText:
      "달콤하지 않으면 과자라고 명명하지 않게 해달라는 청원 넣었을 스타일, 링크 보내면서 참여해달라고 할 스타일, 미안하지만 첫댓 빌릴게 빌런",
    hero: "헐크",
    character: "쫀득, 씁쓸단맛, 엄청 단맛",
    party: "달달파",
    job: "느끼",
    bgColor: "#e6a542",
  },
  {
    clusterID: 6,
    name: "짠돌이",
    preferFlavor: "극강의 짠맛",
    typeText: "아우짜 이거 뭐야 하는데 혼자 존맛이라고 할 스타일, 부모님한테 싱겁다고 반찬투정하다가 짜게 맞을 스타일",
    hero: "조커",
    character: "극강의 짠맛",
    party: "짭짤파",
    job: "자학",
    bgColor: "#a6e0f1",
  },
  {
    clusterID: 7,
    name: "쿰척이",
    preferFlavor: "과자 사이사이 공기가 많은 담백한 맛",
    typeText: "쿰척쿰척 맛, 맛 반 공기 반 부탁해요 파, 씹는 맛에 하나 두개 집어먹을 스타일",
    hero: "할리퀸",
    character: "소신확실, 매니아",
    party: "아싸파",
    job: "소확행",
    bgColor: "#ffa788",
  },
  {
    clusterID: 8,
    name: "실속이",
    preferFlavor: "촘촘하게 담백한 맛",
    typeText: "질소는 안락사할 때만 써주세요 파, 실속없으면 창문으로 나가 스타일",
    hero: "스파이더맨",
    character: "실속챙기는",
    party: "복수전공파",
    job: "주부",
    bgColor: "#febc82",
  },
]

export default clusterList
