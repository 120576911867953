import createReducer from "../common"
import api from "../../api/index"
import DETAIL_INITIAL_STATE from "./state"

const id = "detail"

/* Action Constants */
const SET_PRODUCT_ID = `${id}/SET_PRODUCT_ID`
const SET_PRODUCT_INFO = `${id}/SET_PRODUCT_INFO`
const SET_PRODUCT_EVALUATION_INFO = `${id}/SET_PRODUCT_EVALUATION_INFO`
const SET_TAG_INFO = `${id}/SET_TAG_INFO`
const SET_DISCOUNT_TAG_INFO = `${id}/SET_DISCOUNT_TAG_INFO`
const SET_COMMENTS = `${id}/SET_COMMENTS`

const SET_PRODUCT_RATING = `${id}/SET_PRODUCT_RATING`

const INCREASE_PRODUCT_LIKE = `${id}/INCREASE_PRODUCT_LIKE`
const CANCEL_PRODUCT_LIKE = `${id}/CANCEL_PRODUCT_LIKE`

const INCREASE_COMMENT_LIKE = `${id}/INCREASE_COMMENT_LIKE`
const CANCEL_COMMENT_LIKE = `${id}/CANCEL_COMMENT_LIKE`
const SET_IS_LOADING = `${id}/SET_IS_LOADING`

/* Actions Creators */
export const setProductID = productID => ({ type: SET_PRODUCT_ID, productID })
export const setProductInfo = productInfo => ({ type: SET_PRODUCT_INFO, productInfo })
export const setProductEvaluationInfo = productEvaluationInfo => ({ type: SET_PRODUCT_EVALUATION_INFO, productEvaluationInfo })
export const setTagInfo = tagInfo => ({ type: SET_TAG_INFO, tagInfo })
export const setDiscountTagInfo = tagInfo => ({ type: SET_DISCOUNT_TAG_INFO, tagInfo })
export const setComments = comments => ({ type: SET_COMMENTS, comments })

export const increaseProductLike = () => ({ type: INCREASE_PRODUCT_LIKE })
export const cancelProductLike = () => ({ type: CANCEL_PRODUCT_LIKE })

export const increaseCommentLike = commentID => ({ type: INCREASE_COMMENT_LIKE, commentID })
export const cancelCommentLike = commentID => ({ type: CANCEL_COMMENT_LIKE, commentID })

export const setIsLoading = isLoading => ({ type: SET_IS_LOADING, isLoading })

/* Thunk Middlewares */
export const getProductInfo = productID => dispatch => {
  dispatch(setProductID(productID))

  dispatch(setIsLoading(true))

  api
    .getProductInfoV2(productID)
    .then(res => {
      dispatch(setProductInfo(res.data[0][0]))
      dispatch(setIsLoading(false))
    })
    .catch(err => {
      console.log("error while fetching APIs")
    })
}

export const getComments = productID => dispatch => {
  api
    .getComment(productID)
    .then(res => {
      dispatch(setComments(res.data))
    })
    .catch(res => {
      dispatch(setComments([]))
    })
}

export const getProductEvaluationInfo = productID => dispatch => {
  api
    .getProductEvaluationInfo(productID)
    .then(res => {
      dispatch(setProductEvaluationInfo(res.data[0]))
    })
    .catch(err => {
      console.log(err)
    })
}

export const productLikeRequest = productID => (dispatch, getState) => {
  const { ifUserLikeProduct } = getState().detail.productEvaluationInfo

  if (!ifUserLikeProduct) {
    api.increaseProductLike(productID).then(() => {
      dispatch(increaseProductLike())
    })
  } else {
    api.cancelProductLike(productID).then(() => {
      dispatch(cancelProductLike())
    })
  }
}

export const commentLikeRequest = commentID => (dispatch, getState) => {
  let ifUserLikeComment

  getState().detail.comments.forEach(item => {
    if (item.commentID === commentID) ifUserLikeComment = item.ifUserLikeComment
  })

  if (!ifUserLikeComment) {
    api.increaseCommentLike(commentID).then(() => {
      dispatch(increaseCommentLike(commentID))
    })
  } else {
    api.cancelCommentLike(commentID).then(() => {
      dispatch(cancelCommentLike(commentID))
    })
  }
}

/* Reducer */
const reducer = createReducer(DETAIL_INITIAL_STATE, {
  [SET_PRODUCT_ID]: (state, action) => (state.productID = action.productID),
  [SET_PRODUCT_INFO]: (state, action) => (state.productInfo = action.productInfo),
  [SET_PRODUCT_EVALUATION_INFO]: (state, action) => (state.productEvaluationInfo = action.productEvaluationInfo),
  [SET_TAG_INFO]: (state, action) => (state.tags = action.tagInfo),
  [SET_DISCOUNT_TAG_INFO]: (state, action) => (state.discountTags = action.tagInfo),
  [SET_COMMENTS]: (state, action) => (state.comments = action.comments),

  [SET_PRODUCT_RATING]: (state, action) => {},

  [INCREASE_PRODUCT_LIKE]: state => {
    state.productEvaluationInfo.likeCount++
    state.productEvaluationInfo.ifUserLikeProduct = 1
  },
  [CANCEL_PRODUCT_LIKE]: state => {
    state.productEvaluationInfo.likeCount--
    state.productEvaluationInfo.ifUserLikeProduct = 0
  },
  [INCREASE_COMMENT_LIKE]: (state, action) => {
    state.comments.forEach(item => {
      if (item.commentID === action.commentID) {
        item.likeCount++
        item.ifUserLikeComment = 1
      }
    })
  },
  [CANCEL_COMMENT_LIKE]: (state, action) => {
    state.comments.forEach(item => {
      if (item.commentID === action.commentID) {
        item.likeCount--
        item.ifUserLikeComment = 0
      }
    })
  },
  [SET_IS_LOADING]: (state, action) => (state.isLoading = action.isLoading),
})

export default reducer
