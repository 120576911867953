import React, { Component } from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"

/* 튜토리얼이 진행되는 동안 렌더링되는 컴포넌트 --> 로그인 되어 있지 않은 유저는 튜토리얼 수행 불가 */

export class TutorialService extends Component {
  render() {
    const { authenticated } = this.props

    if (!authenticated){
      alert("tutorial service : unauthenticated")
      return <Redirect to="/" />
    }else{
      return <></>
    }
  }
}

const mapStateToProps = state => ({
  authenticated: state.auth.authenticated,
})

export default connect(mapStateToProps)(TutorialService)
